import React from 'react'

const Turbo = () => {
  return (
    <>  <div className="box  p-2 p-md-3 d-flex mb-3">
    <img className="h45" src="img/boost.png" alt="coin" />
    <div className="ps-3">
      <p className="mb-1 h5">Turbo</p>
      <span className="op50"> Coming soon </span>
    </div>
  </div>
    </>
  )
}

export default Turbo