import React from "react";

const Roadmap = () => {
  return (
    <section className="section_p" id="roadmap">
      <div className="container">
        <div className="hadding_box text-center mb-md-5">
          <h2>Roadmap</h2>
        </div>
        <div className="timeline">
          <div className="timeline-container primary">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase1</span>
              </h4>
              <p>Launching of tap to earn game </p>
            </div>
          </div>
          <div className="timeline-container danger">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 2</span>
              </h4>
              <p>Launch of NFT marketplace</p>
              <p>Launching HNH token ico</p>

              <p>Release of initial gaming NFT for the CAT ,horse and birds</p>
              {/* <p className="timeline-subtitle">2 Hours Ago</p> */}
            </div>
          </div>
          <div className="timeline-container success">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 3</span>
              </h4>
              <p>
                Expansion of the gaming ecosystem with the horse and birds game{" "}
              </p>
              <p>Village building&battle game</p>
            </div>
          </div>
          <div className="timeline-container warning">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 4</span>
              </h4>
              <p>Deployment of decentralised voting mechanism</p>
            </div>
          </div>
          <div className="timeline-container">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 5</span>
              </h4>
              <p>Deployment of decentralised exchange(DEX)</p>
            </div>
          </div>
          <div className="timeline-container">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 6</span>
              </h4>
              <p>Implementation of mining pools and set up allocation tools</p>
            </div>
          </div>
          <div className="timeline-container">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 7</span>
              </h4>
              <p>
                many more, we'll keep it secret, so as not to spoil your
                excitement
              </p>
            </div>
          </div>

          {/* <div className="timeline-container info">
            <div className="timeline-icon">
              <i className="bi bi-rocket-takeoff"></i>
            </div>
            <div className="timeline-body">
              <h4 className="timeline-title">
                <span className="badge">Phase 6</span>
              </h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aliquam necessitatibus numquam earum ipsa fugiat veniam
                suscipit, officiis repudiandae, eum recusandae neque
                dignissimos. Cum fugit laboriosam culpa, repellendus esse
                commodi deserunt.
              </p>
              <p className="timeline-subtitle">4 Days Ago</p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
