import React , {useState} from "react";
import axios from "axios";
import { BASE_URL } from "../../Baseurl";
import { useToasts } from "react-toast-notifications";
import ApiFetch from "../ApiFetech/ApiFetch";

const JoinTwitter = () => {
  const { userData, refetch } = ApiFetch("/getUser");
  const { addToast } = useToasts();
  const isDisabled = userData?.data?.isTwitter;
  const canClaim = userData?.data?.redirectTwitterLink;
  const  [isJoining, setIsJoining] = useState(false); 
  const handleJoinTheTwitter = async () => {
    setIsJoining(true);
    try {
      const data = await axios.post(
        `${BASE_URL}/joinTwitter`,
        { coin: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      addToast(data.data?.message, {
        appearance: "success",
        autoDismiss: true,
      });
      refetch();
      const modal = document.getElementById('twi');
      const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
      bootstrapModal.hide();
    } catch (err) {
      addToast(err.response?.data?.message || "An error occurred", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000, 
      });
      const modal = document.getElementById('twi');
      const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
      bootstrapModal.hide();
    }finally{
      setIsJoining(false);
    }
  };

  const handleRedirectTwitter = async () => {
    try {
      const data = await axios.post(
        `${BASE_URL}/redirectTwitterChannel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      window.open("https://x.com/Honoss_HNH?t=jly4b3d6n6JI8MVtvABYHg&s=09", "_blank");
      refetch(); 
    } catch (e) {
      addToast("An error occurred while joining Twitter", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <div
       
        className="box p-2 p-md-3 d-flex mb-3 cursor-pointer"
        
        data-bs-toggle= "modal" 
        data-bs-target="#twi" 
      >
        <img className="h45" src="img/twitter.png" alt="coin" />
        <div className="ps-3">
          <h5 className="mb-1 m-tx">
            {!isDisabled
            
              ? "  Follow our X account"
              : "You have already followed our x account"}
          </h5>
          <span className="d-flex align-items-center h5 re_h5">
            <img className="h20" src="img/d-coin.png" alt="coin" />
            <span className="pe-2 ps-2">+10000</span>
          </span>
        </div>
      </div>

      <div
        className="modal fade"
        id="twi"
        tabIndex="-1"
        aria-labelledby="twiLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-body text-center">
              <img className="h45" src="img/twitter.png" alt="reward" />
              <p className="mb-4 h5">Follow our x account</p>
              <img className="h20" src="img/d-coin.png" alt="coin" />{" "}
              <b> +10000</b>
              
              {/* {!canClaim && ( */}
                <button
                  onClick={handleRedirectTwitter}
                  className="invite_button m-2 p-2"
                  // disabled={isDisabled}
                
                >
                  Join
                </button>
              {/* )} */}

              {/* {canClaim && ( */}
                <>
                  {/* <h3 className="mb-3">You have joined Twitter! Claim your reward now.</h3> */}
                  <button
                    style={{
                    opacity: !canClaim || isDisabled ? 0.5 : 1,
                    cursor: !canClaim  || isDisabled?  "not-allowed" : "pointer",
                  }}
                    onClick={!canClaim || isJoining || isDisabled   ? undefined : handleJoinTheTwitter}
                    className="invite_button m-2 p-2"
                   
                  >
                  {isJoining ? "Claiming..." : "Claim"}
                  </button>
                </>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinTwitter;
