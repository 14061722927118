import React from "react";

const TokenOmics = () => {
  return (
    <section className="section_p text-center bg_light" id="tokenomics">
      <div className="container">
        <div className="hadding_box mb-md-5">
          <h2>Tokenomics</h2>
          <p className="">
          The total supply
            of $Honoss HNH tokens is 50 billion (50,000,000,000)
          </p>
        </div>

        <div className="row align-items-center">
          <div className="col-md-6 text-center pe-md-5 mb-4 mb-md-0">
            <img
              className="img-fluid"
              src="/img/tokenomics.png"
              alt="chart-mobile"
            />
          </div>
          <div className="col-md-6 text-center">
            <div className="t_box t_box1">
              <h4>35% to AirDrop </h4>
              {/* <p>Burn and strategic use. At least 1B MTCs will be burned.</p> */}
            </div>
            <div className="t_box t_box2">
              <h4>35% to ICO </h4>
              {/* <p>Market making and liquidity providing.</p> */}
            </div>
            <div className="t_box t_box3">
              <h4>15% to team</h4>
              {/* <p>6 months cliff, 1000 days vesting</p> */}
            </div>
            <div className="t_box t_box4">
              <h4>4% to media</h4>
              {/* <p>12 months cliff, 1000 days vesting</p> */}
            </div>
            <div className="t_box t_box5">
              <h4>3% liquidity
</h4>
            </div>
            <div className="t_box t_box6">
              <h4>3% cashback reserve</h4>
            </div>
            <div className="t_box t_box7">
              <h4>3% partnership</h4>
            </div>
            <div className="t_box t_box8">
              <h4>2% mm incentive</h4>
              {/* <p>
                Investor coins acquired through pre-sale, airdrop and staking
              </p> */}
            
            </div>

          
           
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenOmics;
