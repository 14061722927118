import React, { useState, useEffect , useContext } from "react";
import useAuthState from "../state/authState";
import { register, verifyOTP, resendOtp } from "../Services/Authservice";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { emailPattern, nameEmptyPattern } from "../../Pages/Regex";
import { WebSocketContext } from "../../Context/websocket"; 

import InputComponent from "./Input/InputComponent";
import Navbar from "../../Pages/Navbar";
const Register = () => {
  const  {ip} = useContext(WebSocketContext) 
  const { referralCode } = useParams();
  const { addToast } = useToasts();
  const [countdown, setCountdown] = useState(0);
  const [referralOptional, setReferralOptional] = useState(referralCode);
  const [referralCodeOptionalError, setReferralCodeOptionalError] =
    useState("");
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordErr, setPasswordErr] = useState("")
    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")
  // const referralCode = localStorage.getItem("referralCode");
  const [otpError, setOtpError] = useState("");
  const {
    setIsLoading,
    isLoading,
    userName,
    setUserName,
    email,
    setEmail,
    otp,
    setOtp,
    userNameError,
    setUserNameError,
    emailError,
    setEmailError,
    disable,
    setDisable,
    isRegister,
    setIsRegister,
    isVerifying,
    setIsVerifying,
    isResending,
    setIsResending,
  } = useAuthState();
  const Passwordpattern=/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#%?^-_/$&*_+]).{8,}$/
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/", { replace: true });
    }
  }, [navigate]);
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000); 
    }
    return () => clearTimeout(timer); 
  }, [countdown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("gfdgfdg" ,userName)
    if (name == "name") {
      if (value === "") {
        setUserNameError("Name is required");
      } else if (!nameEmptyPattern.test(value)) {
        setUserNameError("Please enter a valid name");
      } else {
        setUserNameError("");
      }
      setUserName(value);
    } else if (name == "email") {
      if (value.trim() === "") {
        setEmailError("Email is required");
      } else if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email");
      } else {
        setEmailError("");
      }
      setEmail(value);
    } else if (name === "otp") {
      if (value.trim() === "") {
        setOtpError("OTP is required");
      } else if (value.length > 6) {
        setOtpError("Invalid Otp");
      } else if (value.length < 6) {
        setOtpError("Invalid Otp");
      } else {
        setOtpError("");
      }
      setOtp(value);
    } else if (name === "referralCode") {
    
      setReferralOptional(value);
    }
  };
  // console.log(referralCode)
  const handleRegister = async (e) => {
    e.preventDefault();
    if (!userName) {
      setUserNameError("Name is required");
      return;
    }
    if (!nameEmptyPattern.test(userName)) {
      setUserNameError("Please enter a valid name");
      return;
    }
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    if (password === "") {
      setPasswordErr("This field is required");
      return false;
    }
    // if (!Passwordpattern.test(password)) {
    //   setPasswordErr(
    //     "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
    //   );
    //   return false;
    // }
    if (password.length<6 || password.length>16) {
      setPasswordErr(
        "Password must contain 6 to 16 characters"
      );
      return false;
    }
    if (confirmPassword === "") {
      setConfirmPasswordErr("This field is required");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordErr("Confirm password does't matched");
      return false;
    }
    setIsLoading(true);

    try {
      setDisable(true);
      const userData = await register({
        userName,
        email,
        referralCode:referralOptional,
        ip,
        confirmPassword,
        password
      });
      if (userData.status) {
        setIsRegister(true);
        window.location.href="/tap-tap"
        // navigate("/tap-tap", { replace: true });
        addToast("Email verified successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        localStorage.setItem("token", userData.token);
        // addToast("Otp sent successfully", {
        //   appearance: "success",
        //   autoDismiss: true,
        //   autoDismissTimeout: 500,
        // });
        // setOtp(userData.data.otp);
      }
    } catch (e) {
      setDisable(false);
      addToast(`${e}`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
      setTimeout(function () {
        setDisable(false);
      }, 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("Otp is required");
      return;
    }
    if (otp.length > 6 || otp.length < 6) {
      setOtpError("Invalid Otp");
      return;
    }
    setIsVerifying(true);
    try {
      const userData = await verifyOTP({ email, otp });
      if (userData.status) {
        navigate("/tap-tap", { replace: true });
        setOtp("");
        // console.log(userData?.token, "userData");
        localStorage.setItem("token", userData.token);

        addToast("Email verified successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setTimeout(function () {
          setIsVerifying(false);
        }, 2000);
      }
    } catch (e) {
      // console.log(e)
      addToast(`${e?.message}`, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 550,
      });
      setTimeout(function () {
        setIsVerifying(false);
      }, 2000);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    // if (!otp) {
    //   setOtpError("OTP is required");
    //   return;
    // }
    // if(otp.length > 6 || otp.length < 6){
    //   setOtpError("OTP will be only 6 digits");
    //   return;
    // }
    setIsResending(true);

    try {
      const userData = await resendOtp({ email, otp });
      if (userData.status) {
        addToast("Otp resend successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      // console.log(userData?.data);
      // setOtp(userData.data);
      setCountdown(120);
      setOtpError("");
    } catch (e) {
      // console.log(e)
      addToast(`${e.message}`, {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setIsResending(false);
    }
  };

  const handlePassword=async(e)=>{
    const {name,value}=e.target
    if (name === "password") {
      setPassword(value)
      if (value === "") {
        setPasswordErr("This field is required");
        return false;
      }
      // if (!Passwordpattern.test(value)) {
      //   setPasswordErr(
      //     "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
      //   );
      //   return false;
      // }
      if (value.length<6 || value.length>16) {
        setPasswordErr(
          "Password must contain 6 to 16 characters"
        );
        return false;
      }
      setPasswordErr("");

      if (confirmPassword !== "") {
        if (confirmPassword !== value) {
          setConfirmPasswordErr("Confirm password does't matched");
          return false;
        }
        setConfirmPasswordErr("");
      }
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value)
      if (value === "") {
        setConfirmPasswordErr("This field is required");
        return false;
      }
      if(password!==""){
      if (password !== value) {
        setConfirmPasswordErr("Confirm password does't matched");
        return false;
      }
    }
      setConfirmPasswordErr("");
    }
  }

  return (
    <>
      {/* <Navbar /> */}
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/img/logo.png" alt="Logo" />
          </a>

          {/* <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a> */}
        </div>
      </nav>
      <section className="new_section section_p bg-man">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-6 m-auto">
              <div className="box p-3 p-md-4">
                <h4 className="text-center">Register</h4>
                {/* {!isRegister ? ( */}
                  <form onSubmit={handleRegister}>
                    <>
                      <InputComponent
                        name="name"
                        value={userName}
                        onChange={handleChange}
                        placeholder="Enter Name"
                        label="Full Name"
                        error={userNameError}
                      />
                      <InputComponent
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Enter Email"
                        label="Email"
                        type="email"
                        error={emailError}
                      />
                      <InputComponent
                        name="password"
                        value={password}
                        onChange={handlePassword}
                        placeholder="Enter Password"
                        label="Passsword"
                        type="password"
                        error={passwordErr}
                      />
                      <InputComponent
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handlePassword}
                        placeholder="Enter Confirm Password"
                        label="Confirm Passsword"
                        type="password"
                        error={confirmPasswordErr}
                      />
                      <InputComponent
                        name="referralCode"
                        value={referralOptional}
                        onChange={handleChange}
                        placeholder="Enter Referral Code (Optional)"
                        label="Referral Code"
                        type="text"
                        error={referralCodeOptionalError}
                      />
                    </>
                    <button
                      disabled={disable}
                      type="submit"
                      className="btn_man w100"
                    >
                      {disable ? "Loading..." : "Register"}
                    </button>
                  </form>
               {/* ) : (
                   <>
                   <form onSubmit={handleVerifyOtp}>
                     <div className="position-relative">
                        <InputComponent
                         name="otp"
                         value={otp}
                         onChange={handleChange}
                        placeholder="Enter OTP"
                       label="OTP"
                         onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                             event.preventDefault();
                            }
                         }}
                          error={otpError}
                      />

                       <div className="btn-center">
                          <button
                           type="submit"
                          className="btn_man ml-10"
                            disabled={isVerifying}
                           style={{ cursor: "pointer" }}
                         >
                      {isVerifying ? "Loading..." : "Verify OTP"}
                          </button>
                         <button
                            type="submit"
                          className="btn_man otp_btn"
                            disabled={isResending || countdown > 0}
                            onClick={handleResendOtp}
                           style={{ cursor: "pointer" }}
                          >
                            {isResending
                              ? "Loading..."
                            : countdown > 0
                            ? `Resend OTP ${countdown}`
                             : "Resend OTP"}
                          </button>
                        </div>
                    </div>
                   </form>
                  </>
                 )} */}
                <div className="form-group text-center mt-3">
                  <span>Already have an account ? </span>
                  <NavLink className="color1" to="/login">
                    Login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
