import React, { useState } from "react";
import ApiFetch from "../ApiFetech/ApiFetch";
import claimTheReward from "./ClaimReward";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const DailyRewardPopup = () => {
  const { addToast } = useToasts();
  const { loading, refetch, userData: rewardData } = ApiFetch("/getUerReward");
  const [isClaiming, setIsClaiming] = useState(false);  
  const handleClick = async (day) => {
    setIsClaiming(true);
    try {
      const data = await claimTheReward(day);

      if (data) {
        addToast(data.message, {
          type: "success",
          appearance: "success",
          autoDismiss: true,
        });
      }
      refetch();
      const modal = document.getElementById("infocircle");
      const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
      bootstrapModal.hide();
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setIsClaiming(false);
    }
  };

  if (
    loading ||
    !rewardData ||
    !rewardData.data ||
    !rewardData.data[0] ||
    !rewardData.data[0].userReward
  ) {
    return <div>Loading...</div>;
  }

  const todayDate = moment().format("YYYY-MM-DD");
  // console.log(todayDate);
  const todayReward = rewardData.data[0].userReward.find(
    (item) => moment(item.date).format("YYYY-MM-DD") == todayDate
  );

  const isTodayClaimed =
    todayReward &&
    todayReward.claimedDate &&
    moment(todayReward.claimedDate).format("YYYY-MM-DD") == todayDate;

  const isClaimable = todayReward && !isTodayClaimed;

  return (
    <>
      <div className="modal" id="infocircle">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="text-center">
                <img className="h45" src="img/reward.png" alt="coin" />
                <p className="mb-4 h5">Claim Your Daily Reward</p>
                <p className="mb-4 h5 mt-1">
                  Accrue coins for logging into the game daily without skipping
                </p>
                <div className="row daily-reward">
                  {rewardData.data[0].userReward?.map((item) => {
                    const isToday =
                      moment(item.date).format("YYYY-MM-DD") == todayDate;
                    const isClaimed =
                      item.claimedDate &&
                      moment(item.claimedDate).format("YYYY-MM-DD") == todayDate;
                    const isClaimable = isToday && !isClaimed;

                    return (
                      <div className="col-3" key={item.day}>
                        <div
                          // onClick={() =>
                          //   isClaimable && handleClick(item.reward)
                          // }
                        >
                          <div className={`reward-box-amount ${item.claimed ? "active" : ""}`}>
                            <p>{item.reward}</p>
                            <img
                              className="h20"
                              src="img/d-coin.png"
                              alt="coin"
                            />
                            <b>{item.tokenDeducted}</b>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {isClaimable ? (
                  <button
                    className={`btn btn-primary come-back ${isClaiming ? "disabled" : ""}`}
                    onClick={() => handleClick(todayReward.reward)}
                    disabled={isClaiming}
                  >
                    {isClaiming ? "Claiming..." : "Claim Now"}
                  </button>
                ) : (
                  <button className="btn btn-primary come-back" disabled>
                    Come Back Tomorrow
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyRewardPopup;
