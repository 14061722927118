import React from "react";
import { NavLink } from "react-router-dom";
const Airdrop = () => {
  return (
    <>
    <nav className="navbar navbar-expand-sm nav_fix">
    <div className="container">
      <NavLink className="navbar-brand" href="/"> <img src="img/logo.png" alt="Logo"/></NavLink>
    
      <a href="/login" className=""><i className="bi bi-person fs_35 color1"></i> </a>
    </div>
    </nav>
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            {" "}
            <img src="img/logo.png" alt="Logo" />
          </a>

          <a href="login.html" className="">
            <i className="bi bi-person fs_35 color1"></i>{" "}
          </a>
        </div>
      </nav>
      <section className="app_in">
        <div className="container2 ">
          <div className="text-center">
            <img className="h250" src="img/d-coin2.png" alt="coin" />
          </div>
          <h2 className="text-center mb-4">Airdrop Tasks</h2>
          <p className="text-center">
            Listhig is on its way .Tasks will appear below. Complete them to
            praticpate in The Airdrop
          </p>

          <div className="box  p-4 text-center h4">Coming Soon</div>
        </div>
      </section>

      <div className="footer_box">
        <NavLink to="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </NavLink>
        <a href="/Friends">
          <i className="bi bi-minecart-loaded"></i>
          Friend's
        </a>
        <a href="/Earn">
          <i className="bi bi-people"></i>
          Earn
        </a>
        <a href="/BuySell">
          <i className="bi bi-database"></i>
          Buy Sell
        </a>
        <a className="active" href="/Airdrop">
          <i className="bi bi-arrow-down-up"></i>
          AirDrop
        </a>
      </div>
    </>
  );
};

export default Airdrop;
