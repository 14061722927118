import React, { useState, useEffect } from 'react';

const FullEnergy = ({ userData }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  useEffect(() => {
    const checkEnergy = () => {
      if (userData?.data?.userMinimumEnergy < userData?.data?.userMaximumEnergy) {
        setIsDisabled(true);
        const now = new Date().getTime();
        const reenableTime = now + 3600000; 
        setTimeLeft(3600000);
        const intervalId = setInterval(() => {
          const currentTime = new Date().getTime();
          const remainingTime = reenableTime - currentTime;
          if (remainingTime <= 0) {
            clearInterval(intervalId);
            setIsDisabled(false);
            setTimeLeft(null);
          } else {
            setTimeLeft(remainingTime);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    };
    checkEnergy();
    const intervalId = setInterval(checkEnergy, 60000);

    return () => clearInterval(intervalId);
  }, [userData]);

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div
      data-bs-toggle="modal"
      data-bs-target="#energy_limit"
      className={`box p-2 p-md-3 d-flex mb-3 curso-pointer ${isDisabled ? 'disabled' : ''}`}
      style={isDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
    >
      <img className="h45" src="img/electric.png" alt="coin" />
      <div className="ps-3">
        <p className="mb-1 h5">Full energy</p>
        <span className="op50">
          {userData?.data?.userMinimumEnergy}/
          {userData?.data?.userMaximumEnergy} Available
        </span>
        {isDisabled && (
          <div className="mt-2 text-danger">
            Time left: {timeLeft ? formatTime(timeLeft) : 'Calculating...'}
          </div>
        )}
      </div>
    </div>
  );
};

export default FullEnergy;
