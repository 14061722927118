import * as opsService from "./Ops";

import { dailyEarnCodeMatchApi,getUserApi,getUserLeverageApi,getPurchaseLeverageApi,changePasswordApi } from "../Constant/Api";

const dailyEarnCodeMatch = async (data, token) => {
  let result = await opsService.postdata(dailyEarnCodeMatchApi, data, token);
  return result;
};

const changePassword = async (data, token) => {
  let result = await opsService.postdata(changePasswordApi, data, token);
  return result;
};

const getUser = async (data,token) => {
  let result = await opsService.getData(getUserApi, data,token);
  return result;
};
const getUserLeverage = async (data,token) => {
  let result = await opsService.getData(getUserLeverageApi, data,token);
  return result;
};
const getPurchaseLeverage = async (data, token) => {
  let result = await opsService.postdata(getPurchaseLeverageApi, data, token);
  return result;
};
// // /get-top-referral-data

export { dailyEarnCodeMatch,getUser,getUserLeverage,getPurchaseLeverage,changePassword };
