import React, { useEffect, useState } from "react";
import useAuthState from "../state/authState";
import { passwordPattern } from "../../Pages/Regex";
import { useToasts } from "react-toast-notifications";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "../../Pages/Navbar";
import { changePassword } from "../Services/user";
import RedText from "../../Pages/RedText";
import ApiFetch from "../ApiFetech/ApiFetch";

const ChangePassword = () => {
  const { addToast } = useToasts();
  const { userData, refetch } = ApiFetch("/getUser");
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const { isLoading, setIsLoading } = useAuthState();
  const [showoldpassword, setshowoldpassword] = useState("password");
  const [oldeye, setoldeye] = useState("bi-eye-slash");
  const [showpassword, setshowpassword] = useState("password");
  const [eye, seteye] = useState("bi-eye-slash");
  const [showconfirmpassword, setshowconfirmpassword] = useState("password");
  const [eye1, seteye1] = useState("bi-eye-slash");
  const [dToken, setdToken] = useState(null);
  useEffect(() => {
 
    if(userData?.data?.token){
      setdToken(userData?.data?.token);
    }
    
  }, [userData?.data]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/Login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);

  const showOldPassword = () => {
    if (showoldpassword === "password") {
      setshowoldpassword("text");
      setoldeye("bi-eye");
    } else {
      setshowoldpassword("password");
      setoldeye("bi-eye-slash");
    }
  };

  const showPassword = () => {
    if (showpassword === "password") {
      setshowpassword("text");
      seteye("bi-eye");
    } else {
      setshowpassword("password");
      seteye("bi-eye-slash");
    }
  };
  const showconfirmPassword = () => {
    if (showconfirmpassword === "password") {
      setshowconfirmpassword("text");
      seteye1("bi-eye");
    } else {
      setshowconfirmpassword("password");
      seteye1("bi-eye-slash");
    }
  };

  const handlePassword = async (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
      if (value === "") {
        setOldPasswordErr("This field is required");
        return false;
      } else {
        setOldPasswordErr("");
      }
    }
    if (name === "newPassword") {
      setNewPassword(value);
      if (value === "") {
        setNewPasswordErr("This field is required");
        return false;
      }
      // if (!passwordPattern.test(value)) {
      //   setNewPasswordErr(
      //     "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
      //   );
      //   return false;
      // }
      if (value.length<6 || value.length>16) {
        setNewPasswordErr(
          "Password must contain 6 to 16 characters"
        );
        return false;
      }
      setNewPasswordErr("");

      if (confirmPassword !== "") {
        if (confirmPassword !== value) {
          setConfirmPasswordErr("Confirm password does't matched");
          return false;
        }
        setConfirmPasswordErr("");
      }
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
      if (value === "") {
        setConfirmPasswordErr("This field is required");
        return false;
      }
      if (newPassword !== "") {
        if (newPassword !== value) {
          setConfirmPasswordErr("Confirm password does't matched");
          return false;
        }
      }
      setConfirmPasswordErr("");
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if(oldPassword===""){
        setOldPasswordErr("This field is required");
        return false;
    }
    if (newPassword === "") {
      setNewPasswordErr("This field is required");
      return false;
    }
    // if (!passwordPattern.test(newPassword)) {
    //   setNewPasswordErr(
    //     "Password must be 8 characters long, contain at least one upper case letter, one lower case letter, one number, and one special character"
    //   );
    //   return false;
    // }

     if (newPassword.length<6 || newPassword.length>16) {
      setNewPasswordErr(
        "Password must contain 6 to 16 characters"
      );
      return false;
    }

    if (confirmPassword === "") {
      setConfirmPasswordErr("This field is required");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordErr("Confirm password does't matched");
      return false;
    }
    setIsLoading(true);
    setDisable(true)
    let token = localStorage.getItem("token");
    const data={
        oldPassword,newPassword,confirmPassword
    }
    const resp = await changePassword(data,token);
    if(resp.status){
        addToast(resp.message, {
            appearance: "success",
            autoDismiss: true,
          });
          setDisable(false)
          setIsLoading(false);
          localStorage.clear();
          navigate("/");
    }
    else{
        addToast(resp.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setDisable(false)
          setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar />

      <section className="new_section section_p bg-man">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-6 m-auto">
              <div className="box p-3 p-md-4">
                <h4 className="text-center">Change Password</h4>

                <form onSubmit={handleChangePassword} autoComplete="off">
                  <div className="form-group">
                    <label className="fw-normal" htmlFor="email">
                      Old Password
                    </label>
					 <div className="position-relative">
                    <input
                      autoComplete="off"
                      onChange={handlePassword}
                      type={showoldpassword}
                      className="form-control"
                      name="oldPassword"
                      placeholder="Enter Old Password"
                      value={oldPassword}
                    />
					 <i className={`bi ${oldeye} eyr_p position-absolute`} onClick={showOldPassword}></i> 
					  </div>
                    {oldPasswordErr && <RedText>{oldPasswordErr}</RedText>}
                  </div>
                  <div className="form-group">
                    <label className="fw-normal" htmlFor="email">
                      New Password
                    </label>
					 <div className="position-relative">
                    <input
                      autoComplete="off"
                      onChange={handlePassword}
                      type={showpassword}
                      className="form-control"
                      name="newPassword"
                      placeholder="Enter New Password"
                      value={newPassword}
                    />
					 <i className={`bi ${eye} eyr_p position-absolute`} onClick={showPassword}></i> 
					  </div>
                    {newPasswordErr && <RedText>{newPasswordErr}</RedText>}
                  </div>
                  <div className="form-group">
                    <label className="fw-normal" htmlFor="email">
                      Confirm Password
                    </label>
					 <div className="position-relative">
                    <input
                      autoComplete="off"
                      onChange={handlePassword}
                      type={showconfirmpassword}
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      value={confirmPassword}
                    />
					  <i className={`bi ${eye1} eyr_p position-absolute`} onClick={showconfirmPassword}></i> 
					  </div>
                    {confirmPasswordErr && (
                      <RedText>{confirmPasswordErr}</RedText>
                    )}
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="btn_man w100"
                      disabled={disable}
                    >
                      {isLoading ? "Loading..." : "Change Password"}
                    </button>
                  </div>
                </form>
                <div>
                <p className=" alert alert-danger text-center">
                  <strong>Just a quick reminder: You're changing your password, make sure to be careful! If you happen to forget it, unfortunately, there’s no way to recover it. To avoid any hassle, it’s a great idea to take a screenshot or jot it down in a secure notebook.</strong>
                  </p>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
