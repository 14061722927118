import axios from "axios";
import { BASE_URL } from "../../Baseurl";

const claimTheReward = async (day) => {
  try {
    const response = await axios.post(`${BASE_URL}/claimReward`, {day}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
 
    throw error;
  }
};

export default claimTheReward;
