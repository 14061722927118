import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useToasts } from "react-toast-notifications";
const Navbar = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const handleLogout = ()=>{
    navigate("/Login")
    localStorage.clear();
    addToast("logout successfully", {
      appearance: "success",
      autoDismiss: true,
    })
  }
  const logout = () => {
    confirmAlert({
      title: "Confirm to logout",

      buttons: [
        {
          label: "Yes",
          onClick: () => handleLogout(),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  
  return (
    <>
      <nav className="navbar navbar-expand-lg nav_fix nav-highlight2">
        <div className="container">
          <NavLink className="navbar-brand" to="/" onClick={scrollToTop}>
            <img src="/img/logo.png" alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item">
                <a className="nav-link" href="/tap-tap">
                  Game
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tokenomics">
                  Tokenomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#FAQ">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="hnh-Whitepaper.pdf">
                  Whitepaper
                </a>
              </li>
            </ul>
          </div>
          {localStorage.getItem("token") ? (
		  <>
			<div className="dropdown dropdown5">
			  <span className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
				 <i className="bi bi-person  "></i> 
			  </span>
			  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
			  <li>   <a className="wsn p-2 d-block" href="/change-password"> <i className="bi bi-lock me-2"></i> Change Password</a></li>
				<li>  <button className="btn log-out text-white text-start mt-1 w100" style={{background:"transparent"}} onClick={logout} >
				  <i class="bi bi-box-arrow-in-left fs_20 me-2"></i> Logout
				</button> </li>
				 
				 
			  </ul>
			</div>
			</>
           
			
          ) : (
            <NavLink to="/Login" className="login_right">
              <i className="bi bi-person fs_20 me-2"></i> Login
            </NavLink>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
