import React , {useEffect} from "react";
import ApiFetch from "../ApiFetech/ApiFetch";
import FullEnergy from "./FullEnergy";
import Turbo from "./Turbo";
import MultiTap from "./MultiTap";
import {UserBoostService} from "./UserBoostService";
import Energy from "./Energy";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
const Boost = () => {
  const navigate = useNavigate();
    useEffect(() => {
    
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    
  }, []);
  const { addToast } = useToasts();
  const { userData, refetch } = ApiFetch("/getUser");
  // const updateUserFullEnergyBoost = async () => {
  //   const data = await UserBoostService();
  //   refetch()
  //   // if (data?.data?.message) {
  //   //   addToast(data?.message, {
  //   //     type: "success",
  //   //     appearance: "success",
  //   //     autoDismiss: true,
  //   //   });
  //   //   refetch();
  //   // } else {
  //   //   addToast("Something went wrong", {
  //   //     type: "error",
  //   //     appearance: "error",
  //   //     autoDismiss: true,
  //   //   });
  //   // }
  // };
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
          <a href="login.html" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>
      <section className="app_in">
        <div className="container2 ">
          <div className="text-center mb-4">
            <h4 className="mb-4">Your Balance</h4>
            <div className="d-inline-flex align-items-center">
              <img className="h60 me-2" src="img/d-coin.png" alt="coin" />
              <span className="fs40 fw-medium">
                {userData?.data?.userToken}
              </span>
            </div>
            <p className="mt-3 color1 h5">How a Boost works</p>
          </div>

          <h5 className="mb-3">Free daily boosters</h5>

          <FullEnergy userData={userData} />
          <Turbo />

          <h5 className=" mb-3 mt-4">Boosters</h5>
          <MultiTap />
          <Energy />
        </div>
      </section>

      <div className="modal" id="energy_limit">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="text-center">
                <img
                  className=" img-fluid h140 "
                  src="img/electric.png"
                  alt="coin"
                />

                <h2 className="fw-semibold mt-3">Energy limit</h2>
                <p className=" mt-4 mb-3">
                  Recharge Your energy to the maximum and do another found of
                  mining
                </p>

                <span className="color0 h5">
                  <img className="h25" src="img/d-coin.png" alt="coin" />
                  <span className="pe-2 ps-2">Free</span>
                </span>
                <button
                  type="submit"
                  // onClick={updateUserFullEnergyBoost}
                  className="invite_button mt-4"
                >
                  Go Ahead
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_box">
        <a href="tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a href="mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a href="Friends">
          <i className="bi bi-people"></i>
          Friend's
        </a>
        <a className="" href="Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a href="BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>
    </>
  );
};

export default Boost;
