import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { dailyEarnCodeMatch } from "../Services/user";
import { useToasts } from "react-toast-notifications";
import ApiFetch from "../ApiFetech/ApiFetch";
const InputModal = ({ show, handleClose, refetch }) => {
  const { userData } = ApiFetch("/getUser");
  const { addToast } = useToasts();
  const [otp, setOtp] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  });
  const [dailyEarnCodeAmount, setDailyEarnCodeAmount] = useState(null);
  useEffect(() => {
    if (userData?.data?.dailyEarnCodeAmount) {
      setDailyEarnCodeAmount(userData?.data?.dailyEarnCodeAmount);
    } else {
      setDailyEarnCodeAmount(2000);
    }
  }, [userData?.data?.dailyEarnCodeAmount]);

  const [validated, setValidated] = useState(false);

  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^\d?$/.test(value)) {
      setOtp((prevOtp) => ({
        ...prevOtp,
        [name]: value,
      }));
      switch (name) {
        case "digit1":
          if (value) digit2Ref.current.focus();
          break;
        case "digit2":
          if (value) digit3Ref.current.focus();
          else digit1Ref.current.focus();
          break;
        case "digit3":
          if (value) digit4Ref.current.focus();
          else digit2Ref.current.focus();
          break;
        case "digit4":
          if (!value) digit3Ref.current.focus();
          break;
        default:
          break;
      }
    }
  };
  // console.log(dailyEarnCodeAmount , "dailyEarnCodeAmount")
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const allFilled = Object.values(otp).every((digit) => digit !== "");
    if (!allFilled) {
      addToast("Please fill all fields", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 600,
      });
      return;
    }

    const code = Object.values(otp).join("");
    const data = {
      code,
      amount: !dailyEarnCodeAmount || undefined ? 2000 : dailyEarnCodeAmount,
    };
    const token = localStorage.getItem("token");
    const result = await dailyEarnCodeMatch(data, token);

    if (result.status) {
      addToast(`Welcome ${result.message}`, {
        appearance: "success",
        autoDismiss: true,
      });
      refetch();
      setOtp({ digit1: "", digit2: "", digit3: "", digit4: "" });
      digit1Ref.current.focus();
    } else {
      setOtp({ digit1: "", digit2: "", digit3: "", digit4: "" });
      addToast(result.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    }

    handleClose();
  };
  const clearFieldsAndClose = () => {
    // Clear OTP fields
    setOtp({ digit1: "", digit2: "", digit3: "", digit4: "" });
    digit1Ref.current.focus(); // Reset focus to the first input field
    handleClose();
  };

  return (
    <Modal show={show} onHide={clearFieldsAndClose}>
      <button
        type="button"
        className="btn-close align-self-end"
        data-bs-dismiss="modal"
        aria-label="Close"
        onClick={() => handleClose()}
      >
        <i className="bi bi-x-lg "></i>
      </button>
      <div className="modal-scroll">
        <img src="img/d-coin.png" className="d-coin img-fluid" alt="d-coin" />
        <Modal.Header closeButton>
          <Modal.Title>Enter Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between enter-code-modal">
              <Form.Group className="cursor-pointer" controlId="digit1">
                <Form.Control
                  type="text"
                  name="digit1"
                  value={otp.digit1}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center cursor-pointer"
                  ref={digit1Ref}
                  required
                />
              </Form.Group>
              <Form.Group controlId="digit2">
                <Form.Control
                  type="text"
                  name="digit2"
                  value={otp.digit2}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center cursor-pointer"
                  ref={digit2Ref}
                  required
                />
              </Form.Group>
              <Form.Group controlId="digit3">
                <Form.Control
                  type="text"
                  name="digit3"
                  value={otp.digit3}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center cursor-pointer"
                  ref={digit3Ref}
                  required
                />
              </Form.Group>
              <Form.Group controlId="digit4">
                <Form.Control
                  type="text"
                  name="digit4"
                  value={otp.digit4}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center cursor-pointer"
                  ref={digit4Ref}
                  required
                />
              </Form.Group>
            </div>
            {/* <p className="key-tx-img">0/4 Keys received</p> */}
            <div className="d-flex justify-content-center">
              <Button
                disabled={
                  !otp.digit1 || !otp.digit2 || !otp.digit3 || !otp.digit4
                }
                variant="primary"
                type="submit"
                className="mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default InputModal;
