import React, { createContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
export const WebSocketContext = createContext(null);

const WebSocketProvider = ({ children }) => {
 
  const [ws, setWs] = useState(null);
  const [count, setCount] = useState(0);
  const [btnDisable, setBtnDisable] = useState(false);
  const [userBoost, setUserBoost] = useState(1000);
  const [userUpdatedCoin, setUserUpdateCoin] = useState(0);
  const [userDecrementBoostCoins, setUserDecrementBoostCoins] = useState(200);
  const [ip, setIp] = useState("")
  
  const connectWebSocket = useCallback(() => {
   
    const newWs =new WebSocket("wss://stream.honosshnh.com/");
    // const newWs = new WebSocket("ws://192.168.1.65:7081");

    newWs.onopen = () => {
      // console.log("WebSocket connected");
    };

    newWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // if (data.userDecrementBoostCoins !== undefined) {
      //   setUserDecrementBoostCoins(data.userDecrementBoostCoins);
      // }
    };

    // newWs.onclose = () => {
    //   setTimeout(connectWebSocket, 1000); 
    // };

    newWs.onerror = (error) => {
      connectWebSocket()
      // newWs.close();
    };

    setWs(newWs);
    newWs.onclose = () => {
      // console.log("WebSocket disconnected");
      connectWebSocket()

    };
    // return () => {
    //   if (newWs) {
    //     newWs.close();
    //   }
    // };
  }, []);

  useEffect(() => {
    connectWebSocket();

    // return () => {
    //   if (ws) {
    //     ws.close();
    //   }
    // };
  }, [connectWebSocket]);


  const handleWebSocket = (message) => {
    const token = localStorage.getItem("token");
    if (ws ) {
      ws.send(JSON.stringify({ ...message, token }));
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        ws,
        handleWebSocket,
        count,
        setCount,
        userBoost,
        setUserBoost,
        userUpdatedCoin,
        setUserUpdateCoin,
        userDecrementBoostCoins,
        setUserDecrementBoostCoins,
        setBtnDisable,btnDisable,
        ip
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
