import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import { Home } from "./Pages/Home";
import { ToastProvider } from "react-toast-notifications";
// import AuthHOC from "./Components/Auth/AuthHoc";
import WebSocketProvider from "./Context/websocket";
import Tap from "./Components/Tap/Tap";
import Friends from "./Components/Friends/Friends";
import BuySell from "./Components/BuySell/BuyAndSell";
import Earn from "./Components/Earn/Earn";
import AirDrop from "./Components/Airdrop/Airdrop";
// import useAuthCheck from "./Components/Auth/UseAuthCheck";
import MiniGame from "./Components/Mine/MineSection";
import Boost from "./Components/Boost/Boost";
import NotFound from "./Pages/NotFound";
import Level from "./Components/Level/Level";
import ChangePassword from "./Components/Auth/ChangePassword";
// import ApiFetch from "./Components/ApiFetech/ApiFetch";
function App() {
  // const { userData } = ApiFetch("/getUser");
  // const navigate = useNavigate();
  // let x = localStorage.getItem("token")
  
  // useEffect(() => {
  //   if (userData?.data?.token) {
  //     const intervalId = setInterval(() => {
  //       if (JSON.stringify(userData.data.token) !== JSON.stringify(x)) {
  //         localStorage.clear();
  //         navigate("/");
  //       }
  //     }, 3000);

      
  //     return () => clearInterval(intervalId);
  //   }
  // }, []); 
  
  return (
    <>
      <WebSocketProvider>
        <ToastProvider>
          {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/referral/:referralCode" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            <Route path="/tap-tap" element={<Tap />} />
            <Route path="/Friends" element={<Friends />} />
            <Route path="/BuySell" element={<BuySell />} />
            <Route path="/Earn" element={<Earn />} />
            <Route path="/Airdrop" element={<AirDrop />} />
            <Route path="/mine" element={<MiniGame />} />
            <Route path="/boost" element={<Boost />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/level" element={<Level />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Routes>
        </ToastProvider>
      </WebSocketProvider>
    </>
  );
}

export default App;
