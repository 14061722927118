import $ from "jquery"; // Import jQuery

export const createCoin = (event, touch, coinAmount) => {
//  console.log(coinAmount ,  "coinAmount")
    var parentOffset = $(event.currentTarget).offset();
  var relX = (touch ? touch.pageX : event.pageX) - parentOffset.left;
  var relY = (touch ? touch.pageY : event.pageY) - parentOffset.top;
  var newElement = document.createElement('b');
  newElement.className = 'modal';
  newElement.setAttribute('data-animation', 'animated fadeOutUp');
  newElement.textContent = `+${coinAmount}`;
  newElement.style.top = `${relY}px`;
  newElement.style.left = `${relX}px`;
  $(event.currentTarget).append(newElement);
  var animation = newElement.getAttribute('data-animation');
  $(newElement)
    .addClass(animation)
    .delay(1000)
    .queue(function (next) {
      $(this).remove();
      next();
    });

};

// import $ from "jquery"; // Import jQuery

export const createCoinForMobile = (event, touch, coinAmount, fingerCount) => {
  if (!event.currentTarget) {
      console.error('Event currentTarget is undefined');
      return;
  }

  const parentOffset = $(event.currentTarget).offset();
  if (touch && event.touches && event.touches.length >= fingerCount) {
      const touchPoint = event.touches[fingerCount - 1];
      if (touchPoint && touchPoint.pageX !== undefined && touchPoint.pageY !== undefined) {
          const relX = touchPoint.pageX - parentOffset.left;
          const relY = touchPoint.pageY - parentOffset.top;

          const newElement = document.createElement('b');
          newElement.className = 'modal';
          newElement.setAttribute('data-animation', 'animated fadeOutUp');
          newElement.textContent = `+${coinAmount}`;
          newElement.style.top = `${relY}px`;
          newElement.style.left = `${relX}px`;

          $(event.currentTarget).append(newElement);

          const animation = newElement.getAttribute('data-animation');
          $(newElement)
              .addClass(animation)
              .delay(1000)
              .queue(function (next) {
                  $(this).remove();
                  next();
              });
      } else {
          console.error('Touch point is undefined or missing pageX/pageY');
      }
  } else {
      console.error('e.touches is undefined or does not have enough touch points');
  }
};