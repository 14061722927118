
import RedText from "../../../Pages/RedText";
const InputComponent = ({ name, value, onChange, placeholder, type = "text", label, error, ...rest }) => {
    return (
      <div className="form-group">
        {label && <label className="fw-normal" htmlFor={name}>{label}</label>}
        <input
          name={name}
          value={value}
          type={type}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
        {error && <RedText>{error}</RedText>}
        {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
      </div>
    );
  };
  
  export default InputComponent;