// authService.js (or any suitable name)
import axios from "axios";
import { BASE_URL } from "../../Baseurl";

const login = async (email , ip,password) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/LoginForOtp`,
      { email ,  ip,password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error.response.data?.message;
  }
};
const register = async (userData) => {
  const { userName, email, referralCode , ip,password,confirmPassword } = userData;
  //  console.log("fff" ,userData)
  //  return
  try {
    const response = await axios.post(
      `${BASE_URL}/register`,
      {
        userName,
        email,
        referralCode,
        ip,
        password,
        confirmPassword
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error.response.data?.message;
  }
};
const verifyOTP = async ({ email, otp , ip }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/veryOtp`,
      { email, otp  , ip },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const resendOtp = async ({ email, otp }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/resendOtp`,
      { email, otp },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


export { resendOtp, register, verifyOTP , login };
