import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../Context/websocket";
import ApiFetch from "../ApiFetech/ApiFetch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { createCoin, createCoinForMobile } from "./UserTapIncreaseCount";
const Mine = () => {
  const { userData, refetch } = ApiFetch("/getUser");
  const  { userData :tokenData,refetch:tokenRefetch} = ApiFetch("/userToken")
  // console.log(tokenData)
  const navigate = useNavigate();
  const [width, setWidth] = useState(90);
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [countdownActive, setCountdownActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dToken, setdToken] = useState(null);
  const [boostCount, setBoostCount] = useState(1);
  const bootenergyTime = userData?.data?.boostEnergyTime;
  const {
    handleWebSocket,
    count,
    setCount,
    userBoost,
    setUserBoost,
    userUpdatedCoin,
    setUserUpdateCoin,
    userDecrementBoostCoins,
    setUserDecrementBoostCoins,
  } = useContext(WebSocketContext);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (userData?.data) {
      setUserBoost(userData.data.userBoostCoins);
      setUserUpdateCoin(userData.data.userToken);
      setBoostCount(userData.data.userBoostCount);
      setUserDecrementBoostCoins(userData.data.userDecrementBoostCoins);
      setWidth(userData.data.tapImageWidth || 90); // Initialize width
      setdToken(userData.data.token);
    }
    // window.scrollTo({
    //   top: 500,
    //   behavior: "smooth",
    // });
  }, [userData]);
  useEffect(() => {}, [
    boostCount,
    userData?.data?.userToken,
    userData?.data?.userDecrementBoostCoins,
  ]);
  useEffect(() => {
    setInterval(() => {
      
    }, 3000);
  }, [tokenRefetch]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);
  useEffect(() => {
    if (userDecrementBoostCoins === 0 && !countdownActive) {
      const currentTime = Math.floor(Date.now() / 1000);
      const targetTime = Number(bootenergyTime) + 3600;
      const remaining = Math.max(targetTime - currentTime, 0);
      setCountdown(remaining);
      setCountdownActive(true);
    }
  }, [userDecrementBoostCoins, countdownActive, bootenergyTime, refetch]);
  useEffect(() => {
    //  setWidth(userData?.data?.tapImageWidth)
    let interval;
    if (countdownActive) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown == 0) {
            clearInterval(interval);
            refetch();
            setCountdownActive(false);
            setUserDecrementBoostCoins(200);
            handleWebSocket({ type: "updateBoost" });

            refetch();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [countdownActive, refetch, handleWebSocket, userData]);

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setUserDecrementBoostCoins((prev) => {
  //       if (prev < userBoost) {
  //         return prev + 1;
  //       }
  //       return prev;f
  //     });
  //   }, 2000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [userBoost]);
  // console.log(progress,"progress");
  // console.log(countdown,"countdown");

  const handleUpdateCoins = (e) => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
    let percent = (Number(userDecrementBoostCoins) / 200) * 90;
    setWidth(percent);
    handleWebSocket({ type: "tap", percent: percent });
    if (countdownActive) {
      return;
    } else {
      setUserUpdateCoin((prev) => prev + userData?.data?.userBoostCount);
    }
    setUserDecrementBoostCoins((prev) => {
      const newValue = prev - 1;

      if (newValue < 0 || newValue === 0) {
        window.location.reload();
        return 0;
      }
      return newValue;
    });
    createCoin(e, null, boostCount);
    setIsActive((prev) => !prev);
  };
  const handleTouchStart = (e) => {
    e.preventDefault();
    const touchCount = e.touches.length;
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
    if (countdownActive) {
      return;
    } else {
      setUserUpdateCoin((prev) => prev + userData?.data?.userBoostCount);
      createCoinForMobile(e, true, boostCount, touchCount);
    }
    setUserDecrementBoostCoins((prev) => {
      const newValue = prev - 1;

      if (newValue < 0 || newValue === 0) {
        window.location.reload();
        return 0;
      }
      return newValue;
    });
    let percent = (Number(userDecrementBoostCoins) / 200) * 90;
    setWidth(percent);
    handleWebSocket({
      type: "touch",
      percent: percent,
      touchCount: touchCount,
    });
    setIsActive((prev) => !prev);
  };
  useEffect(() => {
    const button = document.getElementById("touch-btn");
    if (countdownActive) {
      return;
    } else {
      if (window.innerWidth > 780) {
        button.addEventListener("click", handleUpdateCoins);
      } else {
        button.addEventListener("touchstart", handleTouchStart);
      }
      return () => {
        if (window.innerWidth > 780) {
          button.removeEventListener("click", handleUpdateCoins);
        } else {
          button.removeEventListener("touchstart", handleTouchStart);
        }
      };
    }
  }, [
    userDecrementBoostCoins,
    countdownActive,
    handleWebSocket,
    userUpdatedCoin,
  ]);
  const userRank = [
    ["Bronze", 5000],
    ["Silver", 50000],
    ["Gold", 500000],
    ["Diamond", 2000000],
    ["Sapphire Flame", 0],
  ];
  useEffect(() => {
    const duration = 3600;
    setProgress(() => ((duration - countdown) / duration) * 100);
  }, [countdown, userData?.data?.userToken]);
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix nav-highlight">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>

      <section className="app_in">
        <div className="container2">
          <div className="d-flex mb-md-2 mb-0 align-items-center">
            <img
              onClick={() => navigate("/level")}
              style={{ cursor: "pointer" }}
              className="h45 me-2"
              src="img/s-coin.png"
              alt="coin"
            />
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/level")}
              className="mb-0 re_h5"
            >
              {userData?.data?.userName}
            </h5>
          </div>

          <div className="row">
            <div className="col-md-4 col-xs-12">
              <div className="d-flex">
                {/* {console.log(userRank)} */}
                {/* <label></label> */}
                <label
                  onClick={() => navigate("/mine")}
                  className="ms-auto cursor-pointer"
                >
                  {userData?.data?.level}
                  <span className="op50">/5</span>
                </label>
              </div>
              <div onClick={() => navigate("/level")} className="progress">
                <div
                  style={{
                    width: `${
                      (userData?.data?.level || 1) >= userRank.length
                        ? 100
                        : Number(
                            (Number(userData?.data?.userToken) /
                              userRank[(userData?.data?.level || 1) - 1][1]) *
                              100
                          ).toFixed(2)
                    }%`,
                    cursor: "pointer",
                  }}
                  className="progress-bar"
                  role="progressbar"
                  // style={{ width:`${(userData?.data?.level || 1) >= userRank.length ?100 : Number(Number(userData?.data?.userToken)/userRank[(userData?.data?.level ||1)-1][1]*100).toFixed(2)}%`, cursor: "pointer" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {/* {(userData?.data?.level || 1) >= userRank.length
                    ? 100
                    : Number(
                        (Number(userData?.data?.userToken) /
                          userRank[(userData?.data?.level || 1) - 1][1]) *
                          100
                      ).toFixed(2)}
                  % */}
                </div>
              </div>
            </div>

            <div className="col-md-8 col-xs-12">
              <ul className="d-flex top_box text-center">
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/mine")}
                  className="pt-1"
                >
                  <img className="h45" src="img/s-coin.png" alt="coin" />
                </li>
                <li>
                  <small className="d-block">Profit Per Hour</small>
                  <div className="d-inline-flex align-items-center tr_box_value fs28">
                    <img className="h32" src="img/d-coin.png" alt="coin" />
                    <span className="top_value">
                      {userData.data?.profitIncrementPerHour.toFixed(0)}
                    </span>
                    <i
                      data-bs-toggle="modal"
                      data-bs-target="#infocircle"
                      for="name"
                      style={{ cursor: "pointer" }}
                      className="bi bi-info-circle fs18 op50"
                    ></i>
                  </div>
                </li>
                <li className="pt-2">
                  {/* <i className="bi bi-gear-fill fs28"></i> */}
                </li>
              </ul>
            </div>
          </div>

          <div className="box box_border p-3 p-md-4">
            <div className="row">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Earn")}
                className="col-4"
              >
                <div className="box2 p-2 p-md-3 h100 text-center reward-coin">
                  <img className="h45" src="img/reward.png" alt="coin" />
                  <label className="fw-normal mb-3 d-block mt-2" htmlFor="name">
                    Daily Reward
                  </label>
                  {/* <p>11:00</p> */}
                  {/* <label className="mb-0 op50" htmlFor="name">
                    11:00
                  </label> */}
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                // onClick={() => navigate("/boost")}
                className="col-4"
                onClick={() => navigate("/mine")}
              >
                <div className="box2 p-2 p-md-3 h100 text-center reward-coin">
                  <img className="h45" src="img/boost.png" alt="coin" />
                  <label className="fw-normal mb-3 d-block mt-2" htmlFor="name">
                    Daily Code
                  </label>
                  {/* <p>11:00</p> */}
                  {/* <label className="mb-0 op50" htmlFor="name">
                    11:00
                  </label> */}
                </div>
              </div>
              <div
                onClick={() => navigate("/Friends")}
                style={{ cursor: "pointer" }}
                className="col-4"
              >
                <div className="box2 p-2 p-md-3 h100 text-center reward-coin">
                  <img className="h45" src="img/combo.png" alt="combo" />
                  <label className="fw-normal mb-3 d-block mt-2" htmlFor="name">
                    Invite 10 Friends and get 100000
                  </label>
                  {/* <p>23:10</p> */}
                  {/* <label className="mb-0 op50" htmlFor="name"></label> */}
                </div>
              </div>
              {/* <div className="col-3">
                <div className="box2 p-2 p-md-3 h100 text-center">
                  <img className="h45" src="img/combo.png" alt="combo" />
                  <label className="fw-normal mb-3 d-block mt-2" htmlFor="name">
                    Mini game
                  </label>
                  <label className="mb-0 op50" htmlFor="name">
                    
                      <span className="cricle-gold">{formatCountdown(countdown)} </span>
                  </label>
                </div>
              </div> */}
            </div>
            <div className="position-relative">
              {countdownActive ? (
                <>
                  {" "}
                  <CircularProgressbar
                    value={progress}
                    text={`${Math.round(progress)}%`}
                    styles={buildStyles({
                      textColor: "#fff",
                      trailColor: "#1c1e37",
                    })}
                  />
                  <img className="tap2_img" src="img/tap.png" alt="tap" />{" "}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="text-center mt-2 mt-md-4">
              <div className="d-inline-flex align-items-center reward-coin">
                <img className="h60 me-2" src="img/d-coin.png" alt="coin" />
                <span className="fs40 fw-medium">
                  {/* {console.log(userUpdatedCoin ,  "userUpdatedCoin")} */}
                  {userUpdatedCoin?.toFixed(0)}
                </span>
              </div>

              <div
                className="container-for-loader"
                style={{ overflow: "hidden" }}
              >
                {countdownActive ? (
                  <div className="countdown mt-4 d-inline-flex align-items-center">
                    <div className="ms-2 fs28">
                      <i className="bi bi-clock"></i>{" "}
                      <h1>{formatCountdown(countdown)}</h1>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      // style={{ width: width ? `${width}%` :userData?.data?.userBoostCount }}
                      className={`m-4 w70 loader ${
                        isActive
                          ? "bounse-img active loader"
                          : "bounse-img loader"
                      } ${countdownActive ? "" : "cursor-pointer"}`}
                      // onClick={handleUpdateCoins}
                      // onTouchStart={handleTouchStart}
                      id="touch-btn"
                    ></div>
                    {/* <img
                   
                    className={`m-4 w70 bounse-img ${countdownActive ? "" : "cursor-pointer"}`}
                    src="img/tap.png"
                    alt="coin"
                    style={{ cursor: "pointer" }}
                  /> */}
                  </>
                )}
                {/* <div className={`doup_img ${isActive ? "active" : ""}`}>
                  {userData?.data?.userBoostCount}
                </div> */}
              </div>

              {countdownActive && (
                <div className="countdown mt-4 d-inline-flex align-items-center"></div>
              )}
            </div>
            <Link to="/earn">
              <button className="gold-btn">
                Play quiz and earn {userData?.data?.dailyEarnCodeAmount || 2000}
              </button>
            </Link>
            <div className="box2 p-md-3 p-2  h5 re_h5 d-flex align-items-center mb-0">
              <div className="d-inline-flex  align-items-center">
                <img
                  className="h35 me-2"
                  src="img/electric.png"
                  alt="electric"
                />
                {userDecrementBoostCoins}/
                {!userData?.data?.userBoostCoins
                  ? "..."
                  : userData?.data?.userBoostCoins}
              </div>
              <div className="d-inline-flex align-items-center ms-auto">
                {/* <img
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/boost")}
                  className="h35 me-2"
                  src="img/boost.png"
                  alt="boost"
                />
                Boost */}
              </div>
            </div>
          </div>
        </div>
        {/* modal for i profit per icon */}
        <div className="modal" id="infocircle">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-end">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
                <div className="text-center">
                  <img
                    className=" img-fluid h250 "
                    src="img/boost.png"
                    alt="coin"
                  />

                  <p className="mb-4 h5">Boost Your Profit</p>

                  <p className="mb-4 h5">
                    Tap the mining menu to buy upgrade for your exchange
                  </p>
                  <p className="mb-4 h5">
                    Earn even when offline up to 3 hours
                  </p>

                  <a href="mine" className="invite_button cursor-pointer">
                    Start Mining
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer_box">
        <a className="active" href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a href="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a href="/Friends">
          <i className="bi bi-people"></i>
          Friend
        </a>
        <a href="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a href="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>
    </>
  );
};

export default Mine;
