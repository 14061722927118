import { BASE_URL } from "../../Baseurl";

export const dailyEarnCodeMatchApi = BASE_URL + "/daily-earn-code-match";
export const getUserApi = BASE_URL + "/getUser";
export const getUserLeverageApi = BASE_URL + "/user-leverage";
export const getPurchaseLeverageApi = BASE_URL + "/purchase-leverage";
export const changePasswordApi=BASE_URL+"/user-change-password"




