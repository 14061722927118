
import { useState } from 'react';
const useAuthState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
   const [showPassword, setShowPassword] = useState(false);
   const [disable , setDisable]   = useState(false);
   const [isRegister, setIsRegister] = useState(false) 
   const [isVerifying, setIsVerifying] = useState(false);
   const [isResending, setIsResending] = useState(false); 
  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };
  return {
    isLoading,
    setIsLoading,
    userName,
    setUserName,
    email,
    setEmail,
    password,
    setPassword,
    otp,
    setOtp,
    userNameError,
    setUserNameError,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
    showPassword,
    setShowPassword,
    handlePasswordShow,
    disable,
    setDisable,
    isRegister,
    setIsRegister,
    isVerifying,
    setIsVerifying,
    isResending,
    setIsResending
  };
};

export default useAuthState;
