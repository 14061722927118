import React, { useState, useEffect } from "react";
import { IMAGE_URL } from "../../Baseurl";
import MinePopup from "./MinePopup";

const RewardSection = ({ MineData, userData, refetch, mineRefetch }) => {
  useEffect(() => {}, [MineData]);
  const [selectedMineId, setSelectedMineId] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const handleMineClick = (mineId, img) => {
    setSelectedMineId(mineId);
    setSelectedImg(img);
  };

  const closePopup = () => {
    setSelectedMineId(null);
    setCloseModal(true);
  };

  if (
    !MineData ||
    !MineData.data ||
    !MineData.data[0] ||
    !MineData.data[0].userMine
  ) {
    return <div>Loading...</div>;
  }
  const imgData = [
    {
      src: "img/mine1.png",
    },
    {
      src: "img/mine2.png",
    },
    {
      src: "img/mine3.png",
    },
    {
      src: "img/mine4.png",
    },
    {
      src: "img/mine5.png",
    },
    {
      src: "img/mine6.png",
    },
    {
      src: "img/mine7.png",
    },
    {
      src: "img/mine8.png",
    },
  ];
  return (
    <>
      <div className="row md-padding">
        {MineData?.data[0]?.userMine?.map((item, index) => {
          const isDisabled = item.level >= 6;
          return (
            <div className="col-6 mb-md-4 mb-2">
              <div
                style={{
                  pointerEvents: isDisabled ? "none" : "auto",
                  opacity: isDisabled ? 0.5 : 1,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
                className="box2 p-2 p-md-3 h100 "
                key={item._id}
                // style={{cursor :"pointer"}}
                // className={`${isDisabled ? "disabled" : ""}`}
                onClick={() => !handleMineClick(item._id, imgData[index].src)}
              >
                <div className="man_h_70">
                  <div className="d-flex align-items-center ">
                    <img
                      className="team_icon"
                      src={imgData[index].src}
                      alt="coin"
                    />
                    <div className="ps-2 ps-md-3">
                      <h6>{item.name}</h6>
                      <p className="op50 mb-0">
                        Profit Per Hour: {item.profitHour}
                      </p>
                      <span className="d-flex align-items-center">
                        <img className="h20" src="img/d-coin.png" alt="coin" />
                        <span className="color1 pe-2 ps-2">
                          +{item.profitHour}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center">
                  <span className="low_border">
                    {item.level >= 6
                      ? "Level Completed"
                      : `Level ${item.level}`}
                  </span>
                  <span className="d-flex align-items-center">
                    <img className="h25" src="img/d-coin.png" alt="coin" />
                    <span className="color1 pe-2 ps-2">+{item.price}</span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {selectedMineId && (
        <MinePopup
          mineId={selectedMineId}
          closePopup={closePopup}
          MineData={MineData}
          userData={userData}
          refetch={refetch}
          mineRefetch={mineRefetch}
          selectedImg={selectedImg}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default RewardSection;
