import React from "react";
import { useNavigate } from "react-router-dom";
import UseAuthCheck from "../../Components/Auth/UseAuthCheck";

const NewSection = () => {
  const navigate = useNavigate();
  // UseAuthCheck();

  const goToTapPage = () => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      navigate("/tap-tap");
    }
  };

  return (
    <section className="new_section section_p">
      <div className="container mt-4">
        <div className="row align-items-center">
          <div className="col-md-5 text-center mb-md-0 mb-4">
            <a>
              <img
                onClick={goToTapPage}
                className="max300"
                src="/img/tap.png"
                alt="banner"
                style={{cursor: 'pointer'}}
              />
            </a>
          </div>
          <div className="col-md-7">
            <a>
              <img
                className="img-fluid br25"
                src="/img/banner.jpg"
                alt="banner"
                
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewSection;
