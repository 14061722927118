import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Baseurl";
import  {useNavigate}  from "react-router-dom";
const ApiFetch = (apiEndPoint) => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const result = await axios.get(`${BASE_URL}${apiEndPoint}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,  
        },
      })
      setUserData(result.data);
    } catch (err) {
      // localStorage.clear("")
      // navigate("/")
    }finally{
      setLoading(false);
    }
  };
  useEffect(() => {
  
    fetchData();
  }, [apiEndPoint]); 
  return { userData , refetch:fetchData,loading };
};

export default ApiFetch;
