import React, { useState } from "react";
import { BASE_URL } from "../../Baseurl";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import ApiFetch from "../ApiFetech/ApiFetch";

const JoinTelegram = () => {
  const { userData, refetch } = ApiFetch("/getUser");
  const { addToast } = useToasts();

  const isDisabled = userData?.data?.isTelegram;
 
  const canClaim = userData?.data?.redirectTelegramLink;
  const [isJoining, setIsJoining] = useState(false);
  const handleJoinTelegramLink = async () => {
    setIsJoining(true);
    try {
      const data = await axios.post(
        `${BASE_URL}/joinTelegram`,
        { coin: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      addToast(data.data?.message, {
        appearance: "success",
        autoDismiss: true,
      });

      refetch(); // Refresh user data to update UI
      const modal = document.getElementById("tele");
      const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
      bootstrapModal.hide();
    } catch (err) {
      addToast(err.response?.data?.message || "An error occurred", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setIsJoining(false);
    }
  };

  const handleRedirectTelegram = async () => {
    try {
      await axios.post(
        `${BASE_URL}/redirectTelegramChannel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      window.open("https://t.me/honoss_hnh", "_blank"); // Open in a new tab
      refetch();
    } catch (e) {
      addToast("An error occurred while joining Telegram", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <div
        style={{cursor:"pointer"}}
        className="box p-2 p-md-3 d-flex mb-3"
        // style={{
        //   pointerEvents: isDisabled ? "none" : "auto",
        //   opacity: isDisabled ? 0.5 : 1,
        //   cursor: isDisabled ? "not-allowed" : "pointer",
        // }}
        data-bs-toggle="modal"
        data-bs-target="#tele" 
      >
        <img className="h45" src="img/tg.png" alt="coin" />
        <div className="ps-3">
          <h5 className="mb-1 m-tx">
            {!isDisabled
             
              ? " Join our TG channel"
              : "You have already joined our TG channel"}
          </h5>
          <span className="d-flex align-items-center h5 re_h5">
            <img className="h20" src="img/d-coin.png" alt="coin" />
            <span className="pe-2 ps-2">+10000</span>
          </span>
        </div>
      </div>

      <div
        className="modal fade"
        id="tele"
        tabIndex="-1"
        aria-labelledby="teleLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-body text-center">
              <img className="h45" src="img/tg.png" alt="reward" />
              <p className="mb-4 h5">
                {isDisabled
                  ? "You have joined Telegram"
                  : "Join our TG channel"}
              </p>
              <img className="h20" src="img/d-coin.png" alt="coin" />{" "}
              <b> +10000</b>
              {/* {!canClaim && ( */}
              <button
                onClick={handleRedirectTelegram}
                className="invite_button m-2 p-2"
                // disabled={isDisabled}
              >
                Join
              </button>
              {/* )} */}
              {/* {canClaim && ( */}
              <>
                <button
                  style={{
                       opacity: !canClaim || isDisabled  ? 0.5 : 1,
                    cursor: !canClaim ||  isDisabled ? "not-allowed" : "pointer",
                  }}
                  disabled={isJoining}
                  onClick={
                    !canClaim || isJoining || isDisabled ? undefined : handleJoinTelegramLink
                  }
                  className="invite_button m-2 p-2"
                  // style={{
                  //   opacity: 1,
                  //   cursor: "pointer",
                  // }}
                >
                  {isJoining ? "Claiming..." : "Claim"}
                </button>
              </>
              {/* )}   */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinTelegram;
