import React, { useContext, useState } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { WebSocketContext } from "../../Context/websocket";
import { Modal, Button } from 'react-bootstrap';
import { BASE_URL } from "../../Baseurl";

const MinePopup = ({
  mineId,
  closePopup,
  MineData,
  refetch,
  mineRefetch,
  selectedImg,
}) => {
  const { setBtnDisable } = useContext(WebSocketContext);
  const { addToast } = useToasts();
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(true); 

  const mineDetails = MineData?.data[0]?.userMine.find(
    (item) => item._id === mineId
  );

  const userMine = async () => {
    setDisable(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/updateUserReward`,
        { mineId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    
      addToast(response.data?.message, {
        type: "success",
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
      setBtnDisable(false);
      setShow(false); // Close the modal

      if (response.data?.data?.status) {
        refetch();
        mineRefetch();
        setBtnDisable(false);
        setTimeout(() => {
          setBtnDisable(true);
        }, 5000);
      
      }
      setShow(false);
      setDisable(false); // Reset disable state
      setTimeout(() => {
        closePopup(); // Ensure the parent popup is also closed
      }, 500); // Slight delay to ensure UI updates

      refetch();
      mineRefetch();
    } catch (e) {
      addToast(e.response?.data?.message || "An error occurred", {
        type: "error",
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
    } finally {
      setDisable(false);
    }
  };

  return (
    <Modal show={show} onHide={closePopup} centered>
      <Modal.Header className="" >
        <Modal.Title className="m-auto ps-3 h5">{mineDetails?.name || "Loading..."}</Modal.Title>
        <i show={show} onClick={closePopup} className="bi bi-x-lg  cursor-pointer"></i>
      </Modal.Header>
      <Modal.Body className="text-center">
        {mineDetails ? (
          <>
            <img
              className="img-fluid h250 level_top_img"
              src={selectedImg || ""}
              alt="coin"
            />
            <p className="mb-4 h5">{mineDetails.description}</p>
            <p>
              Profit Per Hour:{" "}
              <img className="h20 " src="img/d-coin.png" alt="coin" /> +
              {mineDetails.profitHour}
            </p>
            <p>
              Price:{" "}
              <img className="h20" src="img/d-coin.png" alt="coin" />{" "}
              {mineDetails.price}
            </p>
            <Button
              onClick={userMine}
              variant="primary"
              disabled={disable}
            >
              {disable ? "Loading..." : "Go Ahead"}
            </Button>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MinePopup;

// luckey1@gmail.com