import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { getUserLeverage } from "../Services/user";
import ApiFetch from "../ApiFetech/ApiFetch";
import { CLOSING } from "ws";
import { useToasts } from "react-toast-notifications";
import { getPurchaseLeverage } from "../Services/user";
const BuyAndSell = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { userData, refetch } = ApiFetch("/getUser");
  const { userData: userLeverage, refetch: leverageRefetch } =
    ApiFetch("/user-leverage");
  // console.log(leverageRefetch)
  const  { userData :tokenData,refetch:tokenRefetch} = ApiFetch("/userToken")
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", value: "" });
  const [leverage, setleverage] = useState([]);
  const [id, setid] = useState("");
  const [dToken, setdToken] = useState(null);
  const [disable, setDisable] = useState(false);
  const [index, setIndex] = useState(0);
  const [leverageStatus, setLeverageStatus] = useState(false);
  const handleShowModal = (title, value, id, indexes, status) => {
    setModalData({ title, value });
    setShowModal(true);
    setid(id);
    setIndex(indexes);
    setLeverageStatus(status);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    
  }, []);
  useEffect(()=>{
    if(userData?.data?.token){
      setdToken(userData?.data?.token)
    }
  } ,  [userData])
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setInterval(() => {
      
    }, 3000);
  }, [tokenRefetch]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);
  const purchaseLaverage = async () => {
    let token = localStorage.getItem("token");
    const data = { leverageId: id };
    setDisable(true);
    const resp = await getPurchaseLeverage(data, token);

    if (resp.status) {
      addToast(`${resp.message}`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1000,
      });
      handleCloseModal();
      leverageRefetch();
      setDisable(false);
    } else {
      addToast(resp.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1200,
      });
      handleCloseModal();
      leverageRefetch();
      setDisable(false);
    }
  };
  const getUserLeverageData = async () => {
    let token = localStorage.getItem("token");
    const resp = await getUserLeverage(token);

    if (resp.status) {
      setleverage(resp?.data?.Leverage);
    } else {
    }
  };
  useEffect(() => {
    getUserLeverageData();
  }, [userLeverage]);

  function formatNumber(value) {
    if (value === 100000) {
      return "100k";
    } else if (value < 100000) {
      return `${value}k`;
    } else if (value < 1000000) {
      // Convert to lakhs (1L) format
      const lakhs = value / 100000;
      return lakhs % 1 === 0
        ? `${lakhs}L`
        : `${lakhs.toFixed(1).replace(/\.0$/, "")}L`;
    } else {
      const millions = value / 1000000;
      return millions % 1 === 0
        ? `${millions}M`
        : `${millions.toFixed(1).replace(/\.0$/, "")}M`;
    }
  }
  const imageArray = [
    "/img/leverage1.png",
    "/img/leverage2.png",
    "/img/leverage3.png",
    "/img/leverage4.png",
  ];
  // console.log(index)
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix nav-highlight">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>

          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>{" "}
          </a>
        </div>
      </nav>
      <section class="app_in">
        <div class="container2 ">
          <h2 class="text-center mb-4">
            Leverage your Taps{" "}
            <i
              data-bs-toggle="modal"
              data-bs-target="#i-icon"
              for="name"
              style={{ cursor: "pointer" }}
              className="bi bi-info-circle op50"
            ></i>
          </h2>

          <div className="box p-md-4 p-2 text-center">
            <div className="row mt-2 mt-md-0">
              {leverage.length === 0 ? (
                <h1>Loading...</h1>
              ) : (
                leverage.map((item, index) => {
                  const status = item.status;
                  return (
                    <>
                      <div className="col-6 mb-4">
                        <div
                          style={{
                            background: status == true ? "#198754" : "",
                          }}
                          className="box2 lyt_box p-2 p-md-3"
                          onClick={() =>
                            handleShowModal(
                              item?.userBoost,
                              item?.userDeductToken,
                              item?._id,
                              index,
                              item.status
                            )
                          }
                        >
                          <h3>{item.status === true ? "Purchased" : "Buy"}</h3>
                          <h5 className="mb-3">{item?.userBoost}X</h5>
                          <h3 className="mb-0">
                            {formatNumber(item?.userDeductToken)}
                          </h3>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
            {leverageStatus === true ? (
              ""
            ) : (
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className="clost_button">
                  <Modal.Title className="m-auto ps-3 h5">
                    {" "}
                    X POWER : {modalData.title}X{" "}
                  </Modal.Title>
                  {/* <i onClick={handleCloseModal} className="bi bi-x-lg  cursor-pointer"></i> */}
                </Modal.Header>
                <Modal.Body className="text-center">
                  <img
                    className="img-fluid h250 level_top_img"
                    src={imageArray[index]}
                    alt="lye"
                  />
                  <h5>Price:-{modalData.value}</h5>{" "}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={disable}
                    variant="primary"
                    className="m-auto"
                    onClick={purchaseLaverage}
                  >
                    {disable ? "Loading..." : "Submit"}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>

          {/* <button className="btn btn-primary mt-2 box p-4 w100" disabled={true}></button> */}

          <div className="text-center mt-3 box p-4 two-btn">
            <div className="text-end up_i">
              <i
                data-bs-toggle="modal"
                data-bs-target="#buy-sell"
                for="name"
                style={{ cursor: "pointer" }}
                className="bi bi-info-circle op50 "
              ></i>
            </div>
            <div className="row">
              <div className="col-6">
                <button className="btn btn-success w-100">
                  80X <br /> Buy
                  <br /> 100$
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-danger w-100">
                  80X <br /> Sell
                  <br /> 100$
                </button>
              </div>
              <div className="upcom-btn">
                <button className="upcomming">Up Coming</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal" id="i-icon">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal">
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="text-center pt-4">
                <p className="mb-4 h55">
                  This Leverage card will increase your Tap power to 20, 40, 50,
                  60
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="buy-sell">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal">
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="text-center pt-4">
                <p className="mb-4 h55">
                  This is upcoming card, this card can be sell at 100$ each{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* here inforrmation modal comming soon */}
      <div className="footer_box">
        <a href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a href="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a href="/Friends">
          <i className="bi bi-people"></i>
          Friends
        </a>
        <a href="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a className="active" href="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>
    </>
  );
};

export default BuyAndSell;
