import React, { useState,useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewSection from "./HomePage/NewSection";
import Roadmap from "./HomePage/Roadmap";
import Tokenomics from "./HomePage/TokenOmics";
import Faq from "./HomePage/Faq";
// import useAuthCheck from "../Components/Auth/UseAuthCheck";
import Navbar from "./Navbar";
import ApiFetch from "../Components/ApiFetech/ApiFetch";

export const Home = () => {
  const navigate = useNavigate();
  const { userData, refetch } = ApiFetch("/getUser");
  const [dToken, setdToken] = useState(null);

  useEffect(() => {
    if(userData?.data?.token){
      setdToken(userData?.data?.token);
    }
    
  }, [userData?.data]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);
  // useEffect(() => {
  //   if (referralCode) {
  //     localStorage.setItem("referralCode", referralCode);
  //   }
  // }, [referralCode]);
  // const navigate = useNavigate();
  //   useEffect(() => {
  //   if (localStorage.getItem("token") === null) {
  //     navigate("/");
  //   } else {
  //     navigate("/");
  //   }
  // }, [navigate]);
  return (
    <>
      <Navbar />
      <NewSection  />
      <Tokenomics  />
      <Roadmap />
      <Faq />
    </>
  );
};
