import React, { useEffect  , useState } from "react";
import ApiFetch from "../ApiFetech/ApiFetch";

const DailyCombo = () => {
  const [mineCodeAmount , setMineCodeAmount] = useState(0)
  const {userData} = ApiFetch('/getUser')
  useEffect(()=>{
        if(userData?.data?.mineCodeAmount){
          setMineCodeAmount(userData?.data?.mineCodeAmount)
        }else{
          setMineCodeAmount(10000)
        }
  } , [userData?.data?.mineCodeAmount])
  return (
    <>
      <div className="box2 ps-3 ps-md-4   mb-3 d-flex align-items-center">
        <label className="fw-normal mb-0 d-block " for="name">
          Daily Code
        </label>
        <button  className="invite_button invite_button00 ms-auto w_auto m-2 p-2">
          <img className="h20" src="img/d-coin.png" alt="coin" /> {mineCodeAmount}
        </button>
      </div>
    </>
  );
};

export default DailyCombo;
