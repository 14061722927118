import React from 'react'

const MultiTap = () => {
  return (
  <>
   <div className="box  p-2 p-md-3 d-flex mb-3 curso-pointer">
            <img className="h45" src="img/handtab.png" alt="coin" />
            <div className="ps-3">
              <p className="mb-1 h5">Multitap</p>
              <span className="color0">
                <img className="h20" src="img/d-coin-white.png" alt="coin" />
                <span className="pe-2 ps-2">2K - 2 lvl</span>
              </span>
            </div>
          </div>
  </>
  )
}

export default MultiTap