import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DailyRewardPopup from "./DailyRewardPopup";
import JoinTelegram from "./JoinTelegram";
import JoinTwitter from "./JoinTwitter";
import { InviteFriends } from "./InviteFriends";
import InputModal from "./InputModal";
import ApiFetch from "../ApiFetech/ApiFetch";
import { dailyEarnCodeMatch } from "../Services/user";
import axios from "axios";
import { BASE_URL } from "../../Baseurl";
import { useToasts } from "react-toast-notifications";
const Earn = () => {
  const { userData, refetch } = ApiFetch("/getUser");
  const { addToast } = useToasts();
  const  { userData :tokenData,refetch:tokenRefetch} = ApiFetch("/userToken")

  // const {userData , refetch } = ApiFetch("/getUser")
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [earnStatus, setEarnStatus] = useState(null);
  const [dailyEarnCodeAmount, setDailyEarnCodeAmount] = useState(null);
  const [dToken, setdToken] = useState(null);
  const handlePlayQuizClick = () => {
    // "noopener,noreferrer"
    window.open("https://play85.quizikka.com", "_blank");
    setTimeout(() => {
      setShowClaimButton(true);
    }, 30000);
  };
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    setEarnStatus(userData?.data?.dailyEarnStatus);
    if (userData?.data?.dailyEarnCodeAmount) {
      setDailyEarnCodeAmount(userData?.data?.dailyEarnCodeAmount);
    } else {
      setDailyEarnCodeAmount(2000);
    }
    if(userData?.data?.token){
      setdToken(userData?.data?.token);
    }
    
  }, [userData?.data?.dailyEarnStatus]);
// console.log(dToken)
  const [amount, setAMount] = useState(0);
  const dailyEarnCodeMatchForToday = async (e) => {
    try {
      const data = await axios.post(
        `${BASE_URL}/dailyEarnCodeQuiz`,
        {
          amount:
            !dailyEarnCodeAmount || undefined ? 2000 : dailyEarnCodeAmount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (data.data.message) {
        addToast(data.data.message, {
          appearance: "success",
          autoDismiss: true,
        });
        refetch();
      }
    } catch (e) {
      addToast(e.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  {
    console.log(userData?.data?.dailyEarnStatus);
  }
  // useEffect(()=>{
  //  console.log(earnStatus ,  "sdfsdfsdfdffsdfsd")
  // }, [userData?.data?.])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setInterval(() => {
      
    }, 3000);
  }, [tokenRefetch]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix nav-highlight">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>

          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>
      <section className="app_in">
        <div className="container2 ">
          <div className="text-center">
            <img className="h250" src="img/d-coin2.png" alt="coin" />
          </div>
          <h2 className="text-center mb-4">Earn more Coins</h2>

          <h5 className=" mb-3">Daily Task</h5>
          <div
            style={{ cursor: "pointer" }}
            className="box  p-2 p-md-3 d-flex mb-3"
            // className="fw-normal mb-0 d-block curser_pointer"
            data-bs-toggle="modal"
            data-bs-target="#infocircle"
            for="name"
          >
            <img className="h45" src="img/reward.png" alt="coin" />
            <div className="ps-3">
              <p className="mb-1">Claim Your Daily Reward</p>
              <span className="d-flex align-items-center h5 re_h5">
                <img className="h20" src="img/d-coin.png" alt="coin" />{" "}
                <span className="pe-2 ps-2">+27,500</span>{" "}
              </span>
            </div>
          </div>

          {/* <button className="gold-btn " onClick={handleShow} disabled={userData?.data?.dailyEarnStatus}>
            {userData?.data?.dailyEarnStatus
              ? "Coming soon in 1 Hour"
              : `Play quiz and earn ${userData?.data?.dailyEarnCodeAmount || 2000}`}
          </button> */}
          {/* {console.log(userData?.data?.dailyEarnCodeAmount)} */}
          {earnStatus === true ? (
            <button className="gold-btn">Come back in 1 hour</button>
          ) : (
            <button onClick={handlePlayQuizClick} className="gold-btn">
              Play quiz and earn {dailyEarnCodeAmount || 2000}
            </button>
          )}

          {showClaimButton && (
            <>
              {earnStatus ? (
                ""
              ) : (
                <button
                  onClick={dailyEarnCodeMatchForToday}
                  className="gold-btn"
                >
                  Claim
                </button>
              )}
            </>
          )}

          {/* <button className="gold-btn ">
          Play quiz and earn 2,000
          </button> */}

          <InputModal show={show} handleClose={handleClose} refetch={refetch} />
          <div className="clearfix"></div>
          <h5 className=" mb-3 mt-4">Tasks List</h5>
          <JoinTelegram />
          <JoinTwitter />
          {/* <InviteFriends /> */}
          {/* 
          <div className="box  p-2 p-md-3 d-flex mb-3">
            <img className="h45" src="img/i_friends.webp" alt="coin" />
            <div className="ps-3">
              <p className="mb-1">Invite 3 friend</p>
              <span className="d-flex align-items-center h5">
                <img className="h20" src="img/d-coin.png" alt="coin" />{" "}
                <span className="pe-2 ps-2">+10000</span>{" "}
              </span>
            </div>
          </div> */}
        </div>
      </section>

      <div className="footer_box">
        <a href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a href="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a href="/Friends">
          <i className="bi bi-people"></i>
          Friends
        </a>
        <a className="active" href="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a href="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>
      <DailyRewardPopup />
    </>
  );
};

export default Earn;
