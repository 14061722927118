import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import APiFetch from "../ApiFetech/ApiFetch";
import RewardSection from "./RewardSection";
import ComboSection from "./ComboSection";
import DailyCombo from "./DailyCombo";
import { WebSocketContext } from "../../Context/websocket";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {createCoin}  from  "../Tap/UserTapIncreaseCount"
const MieSection = () => {
  const navigate = useNavigate();
  const { userData: mineData, refetch: mineRefetch } = APiFetch("/getMines");
  const { userData: data, refetch } = APiFetch("/getUser");
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [countdownActive, setCountdownActive] = useState(false);
  const bootenergyTime = data?.data?.boostEnergyTime;
  const  [dToken, setdToken] = useState(null);
  const {
    userBoost,
    userDecrementBoostCoins,
    userUpdatedCoin,
    setUserUpdateCoin,
    handleWebSocket,
    setUserDecrementBoostCoins,
  } = useContext(WebSocketContext);
  // const navigate = useNavigate();
  useEffect(() => {
  
  let token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  
}, []);
useEffect(()=>{
  setInterval(()=>{
   refetch()
    }, 3000)
  } ,  [])
   useEffect(()=>{
    if(!localStorage.getItem("token")){
         localStorage.clear()
         navigate("/")
       }
       if(dToken &&  localStorage.getItem("token") &&dToken !== localStorage.getItem("token")){
        localStorage.clear()
        navigate("/")
       }

   },[dToken])
  useEffect(() => {
    if (data?.data?.userToken != undefined) {
      setUserUpdateCoin(data?.data?.userToken);
      setdToken(data?.data?.token);
    }
    setUserDecrementBoostCoins(data?.data?.userDecrementBoostCoins);
  }, [data?.data?.userToken, data?.data?.userDecrementBoostCoins]);

  useEffect(() => {
    if (userDecrementBoostCoins === 0 && !countdownActive) {
      const currentTime = Math.floor(Date.now() / 1000);
      const targetTime = Number(bootenergyTime) + 5400;
      const remaining = Math.max(targetTime - currentTime, 0);

      setCountdown(remaining);
      setCountdownActive(true);
    }
  }, [userDecrementBoostCoins, countdownActive,bootenergyTime]);

  useEffect(() => {
    let interval;
    if (countdownActive) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          // console.log(prevCountdown,"prevCountdown");
          if (prevCountdown == 0) {
            clearInterval(interval);

            setCountdownActive(false);
            setUserDecrementBoostCoins(200);
            handleWebSocket({ type: "updateBoost" });
            refetch();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [countdownActive]);

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };
  const handleUpdateCoins = (e) => {
    if (userDecrementBoostCoins < 0 || userDecrementBoostCoins === 0) {
      return;
    } else {
      setUserDecrementBoostCoins((prev) => prev - data?.data?.userBoostCount);
    }
    handleWebSocket({ type: "tap" });
    refetch();
    setUserUpdateCoin((prev) => prev + data?.data?.userBoostCount);
    createCoin(e , null ,  data?.data?.userBoostCount);
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 2000);
  };
  const userRank =  [
    ["Bronze",5000],["Silver", 50000] , ["Gold",500000], ["Diamond",2000000], [ "Sapphire Flame"  , 0]
  ];
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix nav-highlight">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>
      <section className="app_in">
        <div className="container2 ">
          <div className="row">
            <div onClick={() => navigate("/level")} style={{ cursor: "pointer" }} className="col-md-4 col-xs-12">
              <div className="d-flex">
                {/* <label>Bronze</label> */}
                <label className="ms-auto cursor-pointer">
                {data?.data?.level}<span className="op50">/5</span>
                </label>
              </div>
              <div className="progress">
                <div
                 style={{
              width: `${
                (data?.data?.level || 1) >= userRank.length
                  ? 100
                  : Number(
                      (Number(data?.data?.userToken) /
                        userRank[(data?.data?.level || 1) - 1][1]) *
                        100
                    ).toFixed(2)
              }%`,
            }}
                  className="progress-bar"
                  role="progressbar"
                  // style={{ width: "25%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                 {/* {(data?.data?.level || 1) >= userRank.length ?100 : Number(Number(data?.data?.userToken)/userRank[(data?.data?.level ||1)-1][1]*100).toFixed(2)}% */}
                </div>
              </div>
            </div>

            <div className="col-md-8 col-xs-12">
              <ul className="d-flex  top_box   text-center">
                <li className="pt-1">
                  <img className="h45" src="img/s-coin.png" alt="coin" />
                </li>
                <li>
                  <small className="d-block">Profit Per Hour</small>
                  <div className="d-inline-flex align-items-center tr_box_value fs28">
                    <img className="h32" src="img/d-coin.png" alt="coin" />
                    <span className="top_value">
                      {data?.data?.profitIncrementPerHour.toFixed(0)}
                    </span>
                    {/* <i className="bi bi-info-circle-fill op50"></i> */}
                  </div>
                </li>
                <li className="pt-2">
                  {/* <i className="bi bi-gear-fill fs28"></i> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="box box_border p-3 p-md-4">
            <div className="mb-2 text-end">
              <label
                className="fw-normal mb-0 d-block curser_pointer"
                data-bs-toggle="modal"
                data-bs-target="#infocircle"
                for="name"
              >
                {/* 06:57:24 <i className="bi bi-info-circle-fill"></i> */}
                {/* {(data?.data?.level || 1) >= userRank.length ?100 : Number(Number(data?.data?.userToken)/userRank[(data?.data?.level ||1)-1][1]*100).toFixed(2)}% */}
              </label>
            </div>
            <DailyCombo />
            <div>
              <ComboSection mineRefetch={mineRefetch} data={data} />
            </div>

            <div className="text-center mt-2 mb-2"> 
              <div className="d-inline-flex align-items-center">
                <img className="h60 me-2" src="img/d-coin.png" alt="coin" />
                <span className="fs40 fw-medium">
                  {userUpdatedCoin?.toFixed(0)}
                </span>
              </div>
            </div>

            <h4 className="mb-2 h4_re">PR&Team</h4>
            <RewardSection
              MineData={mineData}
              userData={data}
              refetch={refetch}
              mineRefetch={mineRefetch}
            />

            {/* <div className="position-relative text-center">
              {countdownActive ? (
                <div className="countdown mt-4 d-inline-flex align-items-center">
                  <div className="ms-2 fs28">
                    <div style={{ width: "50px", height: "100px" }}>
                      <CircularProgressbar
                        value={formatCountdown(countdown)}
                        text={formatCountdown(countdown)}
                        styles={buildStyles({
                          // Text size
                          textSize: "16px",
                          // Colors
                          pathColor: `gold`,
                          textColor: "#fff",
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={`m-4 w70 bounse-img ${
                      countdownActive ? "" : "cursor-pointer"
                    }`}
                    onClick={handleUpdateCoins}
                  ></div>
                </>
              )}
       
            </div> */}

            {/* <div className="box2 p-3 h5  d-flex align-items-center font-small">
              <div className="d-inline-flex align-items-center">
                <img
                  className="h35 me-2"
                  src="img/electric.png"
                  alt="electric"
                />
                {!data?.data?.userBoostCoins ? (
                  <p>Loading...</p>
                ) : (
                  <span>
                    {userDecrementBoostCoins}/{data?.data?.userBoostCoins}
                  </span>
                )}
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/boost")}
                className="d-inline-flex align-items-center ms-auto"
              >
                
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div className="footer_box">
        <a href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a className="active" href="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a href="/Friends">
          <i className="bi bi-people"></i>
          Friend
        </a>
        <a href="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a href="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>

      <div class="modal" id="infocircle">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal">
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div class="text-center">
                <img class=" img-fluid h250 " src="img/info.png" alt="coin" />

                <p class="mb-4 h5">
                  Find 3 combo cards and upgrade them up to get a prize!
                </p>

                <button class="invite_button">
                  I hope I'm lucky <i class="bi bi-heart-fill"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MieSection;
