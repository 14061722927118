import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ApiFetch from "../ApiFetech/ApiFetch";
import { FriendService } from "./FriendService";

const Friends = () => {
  const navigate = useNavigate();

  const [dToken, setdToken] = useState(null);
  const [copy, setCopy] = useState(false);
  const { userData } = ApiFetch("/getUser");
  const  { userData :tokenData,refetch:tokenRefetch} = ApiFetch("/userToken")

  const [friendData, setFriendData] = useState([]);
  const textToCopy = `https://honosshnh.com/referral/${userData?.data?.referralCode}`;

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(()=>{
    if (userData?.data?.token) {
      setdToken(userData.data.token);
    }
  }, [userData]);
  useEffect(() => {
    setInterval(() => {
      
    }, 3000);
  }, [tokenRefetch]);
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      navigate("/");
    }
    if (
      dToken &&
      localStorage.getItem("token") &&
      dToken !== localStorage.getItem("token")
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, [dToken]);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopy(true);
      localStorage.setItem("referralCode", userData?.data?.referralCode);
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  useEffect(() => {
    FriendService()
      .then((data) => {
        setFriendData(data.data);
      })
      .catch((error) => {
        console.error("Failed to fetch friends data:", error);
      });
  }, []);

  const handleShare = (platform) => {
    let shareUrl = "";
    const message = `Join me on this amazing platform! Use my referral code: ${userData?.data?.referralCode} ${textToCopy}`;

    if (platform === "whatsapp") {
      shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    } else if (platform === "telegram") {
      shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        textToCopy
      )}&text=${encodeURIComponent(message)}`;
    } else if (platform === "twitter") {
      shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        textToCopy
      )}&text=${encodeURIComponent(message)}`;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix nav-highlight">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>
      <section className="app_in">
        <div className="container2 ">
          <h2 className="text-center">
            Invite friends!{" "}
            <i
              data-bs-toggle="modal"
              data-bs-target="#i-icon"
              for="name"
              style={{ cursor: "pointer" }}
              className="bi bi-info-circle op50"
            ></i>
          </h2>

          <p className="mb-4 text-center">
            You and your friend will receive bonuses
          </p>

          <div className="box p-2 p-md-3 d-flex mb-3">
            <img className="h45" src="img/i_friends.png" alt="coin" />
            <div className="ps-3">
              <h6>Invite a Friends</h6>
              <span className="d-flex align-items-center">
                <img className="h20" src="img/d-coin.png" alt="coin" />
                <span className="color1 pe-2 ps-2">+10000</span>
              </span>
            </div>
          </div>
          <div className="box p-2 p-md-3 d-flex mb-3">
            <img className="h45" src="img/i_friends.png" alt="coin" />
            <div className="ps-3">
              <h6>Invite 10 Friends and get</h6>
              <span className="d-flex align-items-center">
                <img className="h20" src="img/d-coin.png" alt="coin" />
                <span className="color1 pe-2 ps-2">+100000</span>
              </span>
            </div>
          </div>

          {/* <h4 className="mb-4 mt-4 text-center color2">More Bonuses</h4> */}

          {friendData?.friends?.length === 0 ? (
            <div className="box box_r_20 p-2 p-md-3 mb-4 mt-3">
              <h5 className="op50 fw-normal mt-4 mb-4 text-center">
                You haven’t invited anyone yet
              </h5>
            </div>
          ) : (
            <>
              <p className="d-flex h6">
                List of your friends ({friendData?.friends?.length}){" "}
                <span className="ms-auto"></span>
              </p>
              <div className="row">
                {friendData?.friends?.map((friend) => (
                  <div key={friend._id} className="col-md-12 mb-3">
                    <div className="box p-2 p-md-3 d-flex align-items-center ">
                      <i
                        className="bi bi-person-add"
                        style={{ color: "green", fontSize: "50px" }}
                      ></i>
                      <div className="ps-3 ">
                        <h6>name : {friend.userName}</h6>
                        <span className="d-flex align-items-center">
                          <img
                            className="h20"
                            src="img/d-coin.png"
                            alt="coin"
                          />
                          <span className="color1 pe-2 ps-2">
                            {friend.userToken}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="row">
            <div className="col-9">
              <label
                className="fw-normal mb-0 d-block curser_pointer"
                data-bs-toggle="modal"
                data-bs-target="#infocircle"
                htmlFor="name"
              ></label>
              <button
                className="invite_button"
                data-bs-toggle="modal"
                data-bs-target="#infocircle"
              >
                Invite a Friend <i className="bi bi-person-add"></i>
                
              </button>
              <button
             className="invite_button mt-2"
              >
               Referral Code : {userData?.data?.referralCode}
              </button>
            </div>
            <div className="col-3">
              <button onClick={handleCopy} className="invite_button fs30 p-1">
                {copy ? (
                  <i style={{ color: "green" }} className="bi bi-check"></i>
                ) : (
                  <i className="bi bi-copy"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
      {/*  here is the popup fr the i con  */}

      <div className="modal" id="i-icon">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button type="button" class="btn-close" data-bs-dismiss="modal">
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="text-center pt-4">
                <p className="mb-4 h55">
                  If A refers B will receive 10000 coin each. Additionally, if B
                  refers C, B and C will receive 10000 coins each, and A will
                  also earn an extra 5,000 coins
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="infocircle"
        tabIndex="-1"
        aria-labelledby="infocircleLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-body text-center">
              <h3 className="modal-title" id="infocircleLabel">
                Share Invitation
              </h3>
              <div className="mt-4 text-center">
                <button
                  className="share-btn"
                  onClick={() => handleShare("whatsapp")}
                >
                  <img src="img/whatsapp.png" style={{ width: "40px" }} />
                </button>
                <button
                  className="share-btn"
                  onClick={() => handleShare("telegram")}
                >
                  {/* <i className="bi bi-telegram" style={{ fontSize: "40px" }}></i> */}
                  <img src="img/tg.png" style={{ width: "40px" }} />
                </button>
                <button
                  className="share-btn"
                  onClick={() => handleShare("twitter")}
                >
                  <img src="img/twitter.png" style={{ width: "40px" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_box">
        <a href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <a href="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </a>
        <a className="active" href="/Friends">
          <i className="bi bi-people"></i>
          Friend
        </a>
        <a href="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </a>
        <a href="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </a>
      </div>
    </>
  );
};

export default Friends;
