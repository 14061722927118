import axios from "axios";
import React from "react";
import { BASE_URL } from "../../Baseurl";
const FriendService = async () => {
  const data = await axios.get(`${BASE_URL}/getAllFriendList`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return data;
};

export { FriendService };
