import React from "react";

const RedText = ({ children }) => {
  return (
    <>
      <span style={{ color: "red" }}>{children}</span>
    </>
  );
};

export default RedText;
