import React from "react";

const Faq = () => {
  return (
    <section class="section_p bg_light" id="FAQ">
      <div class="container">
        <div class="hadding_box text-center mb-md-5">
          <h2>FAQ</h2>
        </div>
        <div class="accordion" id="regularAccordionRobots">
          <div class="accordion-item">
            <h2 id="regularHeadingFirst" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseFirst"
                aria-expanded="true"
                aria-controls="regularCollapseFirst"
              >
                What is Honoss?
              </button>
            </h2>
            <div
              id="regularCollapseFirst"
              class="accordion-collapse collapse "
              aria-labelledby="regularHeadingFirst"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                Honoss is a groundbreaking platform that leverages blockchain
                technology to create a fully integrated Web3 gaming ecosystem.
                It is the first web-based tap-to-earn game that combines a
                decentralized exchange (DEX), NFT marketplace, and BTC mining
                into one comprehensive environment. At its core, Honoss
                redefines digital asset ownership through innovative features
                and seamless integration, providing users with an immersive
                gaming experience where virtual assets acquire real-world value
                through interaction and strategic use.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond"
                aria-expanded="false"
                aria-controls="regularCollapseSecond"
              >
                What is the HNH token, and how is it used?
              </button>
            </h2>
            <div
              id="regularCollapseSecond"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                The HNH token is the native currency of the Honoss ecosystem. It
                is used for purchasing NFTs, participating in the voting
                mechanism, setting up mining pools, and engaging in DeFi
                services like borrowing and lending. Additionally, HNH tokens
                are heavily utilized in the gaming ecosystem, and there are
                opportunities for staking and rewards.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond2">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond2"
                aria-expanded="false"
                aria-controls="regularCollapseSecond2"
              >
                How does the gaming ecosystem work in Honoss?
              </button>
            </h2>
            <div
              id="regularCollapseSecond2"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond2"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                Honoss offers a play-to-earn experience where users can invest
                in virtual pets, such as cats, for $300. Players can breed and
                nurture these pets, increasing their value by purchasing NFT
                items like food, medicine, clothing, and shelter. After three
                months of care, the adult pets can be sold for $300. Early
                adopters can benefit from lower prices on the first slots of
                sales.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond3">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond3"
                aria-expanded="false"
                aria-controls="regularCollapseSecond3"
              >
                What is a tap-to-earn game?
              </button>
            </h2>
            <div
              id="regularCollapseSecond3"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond3"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                A tap-to-earn game is a type of mobile or web-based game where
                players earn rewards or tokens through simple, repeated actions,
                like tapping the screen. In Honoss, the tap-to-earn model is
                integrated into our gaming ecosystem, allowing players to earn
                HNH tokens and NFTs through engaging and interactive gameplay.
                This model not only offers entertainment but also provides an
                opportunity to gain real value from in-game activities.
              </div>
            </div>
          </div>

          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond4">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond4"
                aria-expanded="false"
                aria-controls="regularCollapseSecond4"
              >
              What types of NFTs are available on the Honoss platform?
              </button>
            </h2>
            <div
              id="regularCollapseSecond4"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond4"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              The Honoss platform features a variety of NFTs, including cats, horses, birds, and more. With each first transaction of NFTs, a tree is planted as part of our environmental initiative.
              </div>
            </div>
          </div> */}

          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond5">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond5"
                aria-expanded="false"
                aria-controls="regularCollapseSecond5"
              >
              What cryptocurrencies can be traded on the Honoss DEX?
              </button>
            </h2>
            <div
              id="regularCollapseSecond5"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond5"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              Most cryptocurrencies can be traded on the Honoss DEX. The platform prioritizes security and low transaction fees, with plans to introduce a new HNH chain in the future.
              </div>
            </div>
          </div> */}
          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond6">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond6"
                aria-expanded="false"
                aria-controls="regularCollapseSecond6"
              >
              What DeFi services does Honoss offer?
              </button>
            </h2>
            <div
              id="regularCollapseSecond6"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond6"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              Honoss provides decentralized finance (DeFi) services, allowing users to borrow and lend HNH tokens. This service is designed to offer secure and transparent financial transactions within the ecosystem.
              </div>
            </div>
          </div> */}
          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond7">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond7"
                aria-expanded="false"
                aria-controls="regularCollapseSecond7"
              >
              How does the community participate in the governance of Honoss?
              </button>
            </h2>
            <div
              id="regularCollapseSecond7"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond7"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              Honoss utilizes a decentralized autonomous organization (DAO) for governance. Users can participate in voting on new projects and other important decisions within the HNH ecosystem.
              </div>
            </div>
          </div> */}
          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond8">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond8"
                aria-expanded="false"
                aria-controls="regularCollapseSecond8"
              >
              What security measures are in place to protect users on the Honoss platform?
              </button>
            </h2>
            <div
              id="regularCollapseSecond8"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond8"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              Honoss follows best practices in security and aims to keep transaction costs low through innovative technology, including the planned introduction of a new HNH chain.
              </div>
            </div>
          </div> */}
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond9">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond9"
                aria-expanded="false"
                aria-controls="regularCollapseSecond9"
              >
                How can I get help or support with the Honoss platform?
              </button>
            </h2>
            <div
              id="regularCollapseSecond9"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond9"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                For any questions or assistance, users can reach out to our
                dedicated support team on Telegram. The support team is
                available under the name "Honosshnh.support.team."
              </div>
            </div>
          </div>
          {/* <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond10">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond10"
                aria-expanded="false"
                aria-controls="regularCollapseSecond10"
              >
              What are the key milestones in the Honoss project roadmap?
              </button>
            </h2>
            <div
              id="regularCollapseSecond10"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond10"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              The Honoss roadmap includes launching the tap-to-earn game, HNH token ICO, NFT marketplace, and various games like the Cat Game. The platform will also implement decentralized voting, mining pools, and expand the gaming ecosystem
              </div>
            </div>
          </div> */}
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond11">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond11"
                aria-expanded="false"
                aria-controls="regularCollapseSecond11"
              >
                How do I join the Honoss community?
              </button>
            </h2>
            <div
              id="regularCollapseSecond11"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond11"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                You can join our community on Telegram @honoss_hnh, follow us on
                Twitter @honoss_hnh, and connect with us on Instagram
                @honoss_hnh_official. Stay updated and engage with other
                ambitious members for the latest news and updates.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond12">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond12"
                aria-expanded="false"
                aria-controls="regularCollapseSecond12"
              >
                Why are there only 200 taps available per hour in the Honoss
                tap-to-earn game?
              </button>
            </h2>
            <div
              id="regularCollapseSecond12"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond12"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                The 200-tap limit per hour in the Honoss tap-to-earn game is
                designed to help players manage their time effectively and
                maintain a balanced gameplay experience. We understand that time
                is valuable, so this limit ensures that players can enjoy the
                game without feeling the need to be constantly tapping. After
                using the 200 taps, a 1-hour timer starts, giving players a
                break before the next round of taps becomes available. This
                design encourages strategic use of taps, allowing players to
                maximize their rewards while also saving time for other
                activities.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond13">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond13"
                aria-expanded="false"
                aria-controls="regularCollapseSecond13"
              >
                What are Leverage Cards?
              </button>
            </h2>
            <div
              id="regularCollapseSecond13"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond13"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                Leverage Cards are special items in the Honoss tap-to-earn game
                that significantly enhance your tapping power. Each card boosts
                the points you earn per tap, allowing you to accumulate more
                rewards more efficiently.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond14">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond14"
                aria-expanded="false"
                aria-controls="regularCollapseSecond14"
              >
                How many types of Leverage Cards are there?
              </button>
            </h2>
            <div
              id="regularCollapseSecond14"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond14"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
                There are four types of Leverage Cards, each with a different
                point value increase:
                <ul className="list">
                  <li>
                    20-Point Leverage Card: Increases tap power to 20 points per
                    tap.
                  </li>
                  <li>
                    40-Point Leverage Card: Increases tap power to 40 points per
                    tap.
                  </li>
                  <li>
                    50-Point Leverage Card: Increases tap power to 50 points per
                    tap.
                  </li>
                  <li>
                    60-Point Leverage Card: Increases tap power to 60 points per
                    tap.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="regularHeadingSecond10">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#regularCollapseSecond10"
                aria-expanded="false"
                aria-controls="regularCollapseSecond10"
              >
             Why are there ads in the "Play Quiz and Earn Points" feature?

              </button>
            </h2>
            <div
              id="regularCollapseSecond10"
              class="accordion-collapse collapse"
              aria-labelledby="regularHeadingSecond10"
              data-bs-parent="#regularAccordionRobots"
            >
              <div class="accordion-body">
              The ads in the "Play Quiz and Earn Points" feature help generate revenue that directly contributes to improving the Honoss project. By allowing these ads, we can continue enhancing the platform, adding new features, and ensuring a better experience for all users. The presence of ads is carefully balanced to avoid disrupting your gameplay, while also supporting the ongoing development and growth of the Honoss ecosystem.
              </div>
            </div>
          </div> 
        </div>
      </div>
    </section>
  );
};

export default Faq;
