import React, { useState, useContext, useEffect, useRef } from "react";
import { WebSocketContext } from "../../Context/websocket";
import { Form, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../Baseurl";
import ApiFetch from "../ApiFetech/ApiFetch";

const ComboSection = ({ data }) => {
  const { refetch, userData } = ApiFetch("/getUser");
  const { setBtnDisable, btnDisable } = useContext(WebSocketContext);
  const [otp, setOtp] = useState({ digit1: "", digit2: "", digit3: "" });
  const [validated, setValidated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isValidCode, setIsValidCode] = useState(null); // Track validity of the code
  const [mineCode, setMineCode] = useState(null);
  const [claimed,setClaimed] = useState(false)
  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);

  useEffect(() => {
    if (btnDisable) {
      setTimeout(() => {
        setBtnDisable(false);
      }, 10000);
    }
    if(userData?.data?.mineCodeAmount){
      setMineCode(userData?.data?.mineCodeAmount)
    }else{
      setMineCode(10000)
    }
  }, [btnDisable, setBtnDisable, userData]);
  useEffect(()=>{
   if(userData?.data){
    // console.log(userData?.data?.userMineGameStatus , 'userMineGameStatus')
    setClaimed(userData?.data?.userMineGameStatus)
   }
  } , [userData])
//  alert(mineCode, "fsd")
  useEffect(() => {
    if (otp.digit1 && otp.digit2 && otp.digit3) {
      handleSubmit();
    }
  }, [otp]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (/^\d*$/.test(value)) {
  //     setOtp({
  //       ...otp,
  //       [name]: value,
  //     });

  //     if (name === "digit1" && value.length === 1) {
  //       digit2Ref.current.focus();
  //     } else if (name === "digit2" && value.length === 1) {
  //       digit3Ref.current.focus();
  //     }
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setOtp({ digit1: "", digit2: "", digit3: "" });
      digit1Ref.current.focus();
      setIsValidCode(null);
      return;
    }

    if (/^\d*$/.test(value)) {
      setOtp({
        ...otp,
        [name]: value,
      });

      if (name === "digit1" && value.length === 1) {
        digit2Ref.current.focus();
      } else if (name === "digit2" && value.length === 1) {
        digit3Ref.current.focus();
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const combinedOtp = `${otp.digit1}${otp.digit2}${otp.digit3}`;
      const response = await axios.post(
        `${BASE_URL}/dailyMineCode`,
        { code: combinedOtp , amount:!mineCode || undefined ?10000 : mineCode},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.status) {
        setPopupMessage(response.data.message);
        setIsValidCode(true);
        setShowPopup(true);
        

        setTimeout(() => {
          setShowPopup(false);
          setIsValidCode(null);
          setClaimed(true)
          setClaimed(true);
          refetch(); 
        }, 3000);
        setOtp({ digit1: "", digit2: "", digit3: "" });
        digit1Ref.current.focus();
        // refetch()
      } else {
        setIsValidCode(false);
      }
    } catch (error) {
      // console.error("API Error: ", error);
      setIsValidCode(false);
    }
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    if (!/^\d$/.test(value)) {
      // Handle invalid input
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const inputStyle = (isValid) => {
    if (isValid === null) return {}; // No border color when input is neutral
    return isValid ? { borderColor: "green" } : { borderColor: "red" }; // Green for success, red for error
  };

  return (
    <>
  
      {claimed == "true" || userData?.data?.userMineGameStatus == "true" ? (
        <>
        <div style={{display:"flex", flexDirection:"row" , justifyContent:"center" , gap:"20px"}}>
        <h1 className="text-center">CLAIMED</h1>
        <img src="img/right-tick.png"  style={{height:"30px", width:"30px" , marginTop:"10px"}}/>
        </div>
       
        </>
        
      ) : (
        <div className="row">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => e.preventDefault()}
          >
            <div
              className="d-flex justify-content-between enter-code-modal align-input"
              style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <Form.Group controlId="digit1">
                <Form.Control
                  type="text"
                  name="digit1"
                  value={otp.digit1}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center"
                  ref={digit1Ref}
                  required
                  onBlur={handleBlur}
                  style={inputStyle(isValidCode)} // Apply conditional styling
                />
              </Form.Group>
              <Form.Group controlId="digit2">
                <Form.Control
                  type="text"
                  name="digit2"
                  value={otp.digit2}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center"
                  ref={digit2Ref}
                  required
                  onBlur={handleBlur}
                  style={inputStyle(isValidCode)} // Apply conditional styling
                />
              </Form.Group>
              <Form.Group controlId="digit3">
                <Form.Control
                  type="text"
                  name="digit3"
                  value={otp.digit3}
                  onChange={handleChange}
                  placeholder="0"
                  maxLength="1"
                  className="text-center"
                  ref={digit3Ref}
                  required
                  onBlur={handleBlur}
                  style={inputStyle(isValidCode)} // Apply conditional styling
                />
              </Form.Group>
            </div>
          </Form>
          <Modal show={showPopup} onHide={handleClosePopup} centered>
            <Modal.Header closeButton>
              <Modal.Title>Congratulations!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img
                  src="img/winner.jpg"
                  alt="Congratulatory"
                  className="img-fluid mb-3"
                />
                <p>{popupMessage}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="primary" onClick={handleClosePopup}>
                Close
              </Button> */}
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <button
        type="button"
        className="gold-btn mt-3"
        data-bs-toggle="modal"
        data-bs-target="#infocircle"
      >
        Get code
      </button>

      <div
        className="modal fade"
        id="infocircle"
        tabIndex="-1"
        aria-labelledby="infocircleLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg  cursor-pointer"></i>
              </button>
            </div>
            <div className="modal-body text-center">
              <img
                className="img-fluid  level_top_img"
                src="img/get-code-img.png"
               
              />
          
            <h5 className="text-center h55">Tap on X or TG Logo to reveal the code.</h5>
            <h4 className="text-center mb-4">Go ahead</h4>

            <div className="text-center d-flex gap-4 justify-content-center  ms-3">
              <img
                onClick={() =>
                  window.open(
                    "https://x.com/Honoss_hnh?t=xPj9UYvXJyktiKwlPl8fVw&s=09",
                    "_blank"
                  )
                }
                src="img/twitter.png"
                style={{ width: "50px", cursor: "pointer" }}
              />
              <img
                style={{ width: "50px", cursor: "pointer" }}
                onClick={() => window.open("https://t.me/honoss_hnh", "_blank")}
                className="img-fluid"
                src="img/tg.png"
              />
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComboSection;
