// import React, { useEffect } from "react";
// import Level1 from "./Level1";
// import Level2 from "./Level2";
// import Level3 from "./Level3";
// import Level4 from "./Level4";
// import Level5 from "./Level5";
// import ApiFetch from "../ApiFetech/ApiFetch";
// import { NavLink, useNavigate } from "react-router-dom";
// import { NavItem } from "react-bootstrap";
// const Level = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     let token = localStorage.getItem("token");
//     if (!token) {
//       navigate("/");
//     }
//   }, []);
//   const { userData: userLevels } = ApiFetch("/userLevel");
//   // const [userLevelIndex, setUserLevelIndex] = React.useState(0);
//   // console.log(userLevels, "userLevels")
//   const { userData } = ApiFetch("/getUser");
//   const userRank = [
//     ["Bronze", 5000],
//     ["Silver", 50000],
//     ["Gold", 500000],
//     ["Diamond", 2000000],
//     ["Sapphire Flame", 0],
//   ];

//   return (
//     <>
//       <nav className="navbar navbar-expand-sm nav_fix">
//         <div className="container">
//           <a className="navbar-brand" href="/">
//             <img src="img/logo.png" alt="Logo" />
//           </a>
//           <a href="/" className="">
//             <i className="bi bi-person fs_35 color1"></i>
//           </a>
//         </div>
//       </nav>
//       <section className="app_in ">
//         <div className="container2 pb-5">
//           <div id="carouselExampleControls" className="carousel slide">
//             <div className="carousel-inner">
//               <Level1
//                 userLevels={userLevels[1]}
//                 authLevel={userData?.data?.level == 1}
//               />
//               <Level2
//                 userLevels={userLevels[2]}
//                 authLevel={userData?.data?.level == 2}
//               />
//               <Level3
//                 userLevels={userLevels[3]}
//                 authLevel={userData?.data?.level == 3}
//               />
//               <Level4
//                 userLevels={userLevels[4]}
//                 authLevel={userData?.data?.level == 4}
//               />
//               <Level5
//                 userLevels={userLevels[5]}
//                 authLevel={userData?.data?.level == 5}
//               />
//             </div>
//             {/* {console.log(userLevels[1].length)} */}

//             <button
//               className="carousel-control-prev"
//               type="button"
//               data-bs-target="#carouselExampleControls"
//               data-bs-slide="prev"

//             >
//               <span className="carousel-control-prev-icon" aria-hidden="true" />
//               <span className="visually-hidden">Previous</span>
//             </button>
//             <button
//               className="carousel-control-next"
//               type="button"
//               data-bs-target="#carouselExampleControls"
//               data-bs-slide="next"
//             >
//               <span className="carousel-control-next-icon" aria-hidden="true" />
//               <span className="visually-hidden">Next</span>
//             </button>
//           </div>
//         </div>
//       </section>

//       <div className="level_fix">
//         <div className="container2">
//           <div className="box p-2 p-md-3 d-flex align-items-center">
//             <span className="blue_icon">
//               <img className="img-fluid" src="img/level1.jpg" alt="coin" />
//             </span>
//             <div className="ps-3 d">
//               <span className="d-flex align-items-center mb-2">
//                 <img className="h20 me-2" src="img/s-coin.png" alt="coin" />
//                 <h6 className="mb-0">{userData?.data?.userName}</h6>
//               </span>
//               <span className="d-flex align-items-center">
//                 <img className="h20 me-2" src="img/d-coin.png" alt="coin" />
//                 {/* <h5 className="mb-0 op50">{userData?.data?.userProfitPerHour}</h5> */}
//                 {/* Level:<h5 className="mb-0 op50">{userData?.data?.level}</h5> */}
//               </span>
//             </div>
//             <h1 className="ms-auto  l_n_list">
//               {userLevels[userData?.data?.level]?.findIndex(
//                 (i) => i.email === userData?.data?.email
//               ) + 1}
//             </h1>
//           </div>
//         </div>
//       </div>

//       <div className="footer_box">
//         <a href="/tap-tap">
//           <i className="bi bi-hand-index"></i>
//           Tap Tap
//         </a>
//         <NavLink className="active" to="/mine">
//           <i className="bi bi-minecart-loaded"></i>
//           Mine
//         </NavLink>
//         <NavLink to="/Friends">
//           <i className="bi bi-people"></i>
//           Friend
//         </NavLink>
//         <NavLink to="/Earn">
//           <i className="bi bi-database"></i>
//           Earn
//         </NavLink>
//         <NavLink to="/BuySell">
//           <i className="bi bi-arrow-down-up"></i>
//           Buy Sell
//         </NavLink>
//       </div>
//     </>
//   );
// };

// export default Level;
import React, { useEffect, useState, useRef } from "react";
import ApiFetch from "../ApiFetech/ApiFetch";
import { NavLink, useNavigate } from "react-router-dom";
// import { Carousel } from 'react-bootstrap';
const Level = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const { userData: userLevels } = ApiFetch("/userLevel");
  const { userData } = ApiFetch("/getUser");
  const [index, setIndex] = useState(1);
  const userRank = [
    ["Bronze", 5000],
    ["Silver", 50000],
    ["Gold", 500000],
    ["Diamond", 2000000],
    ["Sapphire Flame", 0],
  ];

  useEffect(() => {
    setIndex(userData?.data?.level || 1);
  }, [userData]);
  const levelComponents = [
    {
      imgSrc: "img/level2.jpg",
      title: "BRONZE",
      range: "0-5K",
      levelIndex: 1,
    },
    {
      imgSrc: "img/level3.jpg",
      title: "SILVER",
      range: "5K-50K",
      levelIndex: 2,
    },
    {
      imgSrc: "img/level5.jpg",
      title: "GOLD",
      range: "50K-500K",
      levelIndex: 3,
    },
    {
      imgSrc: "img/level1.jpg",
      title: "DIAMOND",
      range: "500K-2M",
      levelIndex: 4,
    },
    {
      imgSrc: "img/level4.jpg",
      title: "SAPPHIRE FLAME",
      range: "2M+",
      levelIndex: 5,
    },
  ];
  const handlePre = () => {
    setIndex(
      Number(
        document.querySelector(".carousel-item.active").getAttribute("cal-data")
      ) - 1
    );
  };
  const handleNext = () => {
    setIndex(
      Number(
        document.querySelector(".carousel-item.active").getAttribute("cal-data")
      ) + 1
    );
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
          <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a>
        </div>
      </nav>
      <section className="app_in ">
        <div className="container2 pb-5">
          <div id="carouselExampleControls" className="carousel slide">
            <div className="carousel-inner">
              {levelComponents.map((level, index) => (
                <div
                  className={`carousel-item ${
                    index ==
                    (userData?.data?.level ? userData?.data?.level - 1 : 0)
                      ? "active"
                      : ""
                  }`}
                  key={level.levelIndex}
                  cal-data={index + 1}
                >
                  <div className="text-center mb-5 level_top">
                    <img
                      src={level.imgSrc}
                      alt={`level${level.levelIndex}`}
                      className="level_top_img"
                    />
                    <div className="text-center">
                      <h4>
                        {level.title}
                        <br />
                        <b>{level.range}</b>
                      </h4>
                    </div>
                    {userData?.data?.level === level.levelIndex && (
                      <div className="progress mb-3">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              (userData?.data?.level || 1) >= userRank.length
                                ? 100
                                : Number(
                                    (Number(userData?.data?.userToken) /
                                      userRank[
                                        (userData?.data?.level || 1) - 1
                                      ][1]) *
                                      100
                                  ).toFixed(2)
                            }%`,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    )}
                  </div>
                  {userLevels?.[level.levelIndex]?.map((user, index) => (
                    <div
                      key={user._id}
                      className="box p-2 p-md-3 d-flex mb-3 align-items-center"
                    >
                      <span className="blue_icon">
                        <img
                          className="img-fluid"
                          src={level.imgSrc}
                          alt="coin"
                        />
                      </span>
                      <div className="ps-3">
                        <span className="d-flex align-items-center mb-2">
                          <img
                            className="h20 me-2"
                            src="img/s-coin.png"
                            alt="coin"
                          />
                          <h6 className="mb-0">@{user.userName}</h6>
                        </span>
                        <span className="d-flex align-items-center">
                          <img
                            className="h20 me-2"
                            src="img/d-coin.png"
                            alt="coin"
                          />
                          <h5 className="mb-0 op50">
                            {!user.profitIncrementPerHour
                              ? 0
                              : user.profitIncrementPerHour}
                          </h5>
                        </span>
                      </div>
                      <div className="ms-auto l_n_list">{index + 1}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <button
              className={`carousel-control-prev  `}
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              onClick={handlePre}
              disabled={index == 1}
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className={`carousel-control-next `}
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
              onClick={handleNext}
              disabled={index == levelComponents.length}
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      <div className="level_fix">
        <div className="container2">
          <div className="box p-2 p-md-3 d-flex align-items-center">
            <span className="blue_icon">
              <img className="img-fluid" src="img/level1.jpg" alt="coin" />
            </span>
            <div className="ps-3 d">
              <span className="d-flex align-items-center mb-2">
                <img className="h20 me-2" src="img/s-coin.png" alt="coin" />
                <h6 className="mb-0">{userData?.data?.userName}</h6>
              </span>
              <span className="d-flex align-items-center">
                <img className="h20 me-2" src="img/d-coin.png" alt="coin" />
                {/* <h5 className="mb-0 op50">{userData?.data?.userProfitPerHour}</h5> */}
                {/* Level:<h5 className="mb-0 op50">{userData?.data?.level}</h5> */}
              </span>
            </div>
            <h1 className="ms-auto  l_n_list">
              {userLevels[userData?.data?.level]?.findIndex(
                (i) => i.email === userData?.data?.email
              ) + 1}
            </h1>
          </div>
        </div>
      </div>

      <div className="footer_box">
        <a href="/tap-tap">
          <i className="bi bi-hand-index"></i>
          Tap Tap
        </a>
        <NavLink className="active" to="/mine">
          <i className="bi bi-minecart-loaded"></i>
          Mine
        </NavLink>
        <NavLink to="/Friends">
          <i className="bi bi-people"></i>
          Friend
        </NavLink>
        <NavLink to="/Earn">
          <i className="bi bi-database"></i>
          Earn
        </NavLink>
        <NavLink to="/BuySell">
          <i className="bi bi-arrow-down-up"></i>
          Buy Sell
        </NavLink>
      </div>
    </>
  );
};

export default Level;




