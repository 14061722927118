import React, { useEffect, useState , useContext } from "react";
import useAuthState from "../state/authState";
import { verifyOTP, resendOtp, login } from "../Services/Authservice";
import { emailPattern } from "../../Pages/Regex";
import { useToasts } from "react-toast-notifications";
import { NavLink, useNavigate } from "react-router-dom";
import RedText from "../../Pages/RedText";
import Navbar from "../../Pages/Navbar";
// import {WebSocketContext}
import { WebSocketContext } from "../../Context/websocket"; 
const Login = () => {
  const  {ip} = useContext(WebSocketContext) 
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useAuthState();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [disable, setDisable] = useState(false);
  const [password,setPassword]=useState("")
  const [passwordErr,setPasswordErr]=useState("")
  const [isVerifying, setIsVerifying] = useState(false);  
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [showpassword, setshowpassword] = useState("password");
  const [eye, seteye] = useState("bi-eye-slash");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/", { replace: true });
    }
  }, [navigate]);
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000); 
    }
    return () => clearTimeout(timer); 
  }, [countdown]);

  const showPassword = () => {
    if (showpassword === "password") {
      setshowpassword("text");
      seteye("bi-eye");
    } else {
      setshowpassword("password");
      seteye("bi-eye-slash");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    if(password==""){
      setPasswordErr("This field is required")
      return 
    }
    setIsLoading(true);
    setDisable(true);
    try {
      const response = await login(email ,  ip,password );
      if (response.status) {
        addToast("Login successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        localStorage.setItem("token", response.data);
        setIsOtpSent(true);
        setTimeout(() => {
          setDisable(false);
          navigate("/tap-tap", { replace: true })
        }, 2000);
        // setOtp(response.otp);
       
        // console.log(response,  "response")
      } else {
        addToast(response.message, {
          appearance: "error",
          autoDismiss: true,
        });
        setDisable(false);
      }
    } catch (error) {
      // console.log(error)
      addToast(error,{
        autoDismissTimeout: 1000, 
        appearance: "error",
        autoDismiss: true,
      });
      setDisable(false);
    } finally {
      setIsLoading(false);
      // setDisable(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("OTP is required");
      return;
    }

    if(otp.length > 6 || otp.length < 6){
      setOtpError("OTP will be only 6 digits");
      return;
    }
    // setIsLoading(true);
    setIsVerifying(true);
    try {
      const response = await verifyOTP({ email, otp , ip });
      if (response.status) {
        addToast(`Welcome ${response.message}`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1000, 
        });
        // console.log(response.token)
        localStorage.setItem("token", response.token);
        let x= localStorage.getItem("token");
        // console.log(x , "sffsdfdfd")
        navigate("/tap-tap", { replace: true });
      } else {
        addToast(response.message || "Failed to verify OTP", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 1000, 
        });
      }
    } catch (error) {
      addToast(error.message || "OTP verification failed. Please try again.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000, 
      });
    } finally {
      // setIsLoading(false);
      setIsVerifying(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    // if (!otp) {
    //   setOtpError("OTP is required");
    //   return;
    // }
    // if(otp.length > 6 || otp.length < 6){
    //   setOtpError("Invalid Otp");
    //   return;
    // }
    // setIsLoading(true);
    setIsResending(true);
    try {
      const response = await resendOtp({ email, otp });
      if (response.status) {
        addToast("OTP resend successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1000, 
        });
        // setOtp(response.data);
        setCountdown(120); 
        setOtpError("")
      } else {
        addToast(response.message || "Failed to resend OTP", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 1000, 
        });
      }
    } catch (error) {
      addToast(error.message || "Resend OTP failed. Please try again.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1000, 
      });
    } finally {
      // setIsLoading(false);
      setIsResending(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (value.trim() === "") {
        setEmailError("Email is required");
      } else if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email");
      } else {
        setEmailError("");
      }
      setEmail(value);
    }else if (name === "otp") {
      if (value.trim() === "") {
        setOtpError("OTP is required");
      } else if (value.length > 6) {
        setOtpError("Invalid Otp");
      }else if(value.length < 6){
        setOtpError("Invalid Otp");
      } else {
        setOtpError("");
      }
      setOtp(value);
    }
    else if(name==="password"){
      setPassword(value)
      if(value==""){
        setPasswordErr("This field is required")
      }
      else{
        setPasswordErr("")
      }
    }
  };
  //  console.log(otp , "otp")
  return (
    <>
      {/* <Navbar /> */}
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>

          {/* <a href="/" className="">
            <i className="bi bi-person fs_35 color1"></i>
          </a> */}
        </div>
      </nav>
      <section className="new_section section_p bg-man">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-6 m-auto">
              <div className="box p-3 p-md-4">
                <h4 className="text-center">Login</h4>
                {/* {!isOtpSent ? ( */}
                  <form onSubmit={handleLogin} autoComplete="off">
                    <div className="form-group">
                      <label className="fw-normal" htmlFor="email">
                        Email
                      </label>
                      <input
                        autoComplete="off"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Enter Email"
                      />
                      {emailError && <RedText>{emailError}</RedText>}
                    </div>
                    <div className="form-group">
                      <label className="fw-normal" htmlFor="email">
                        Password
                      </label>
					    <div className="position-relative">
                      <input
                        autoComplete="off"
                        onChange={handleChange}
                        type={showpassword}
                        className="form-control"
                        name="password"
                        placeholder="Enter Password"
                        value={password}
                      />
					  		 <i className={`bi ${eye} eyr_p position-absolute`} onClick={showPassword}></i> 
					  </div>
                      {passwordErr && <RedText>{passwordErr}</RedText>}
                  
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn_man w100"
                        disabled={disable}
                      >
                        {isLoading ? "Loading..." : "Login"}
                      </button>
                    </div>
                  </form>
                {/* ) 
                
                : (
                  <form onSubmit={handleVerifyOtp} autoComplete="off">
                    <div className="form-group">
                      <label className="fw-normal" htmlFor="otp">
                        OTP
                      </label>
                      <input
                      value={otp}
                        autoComplete="off"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        name="otp"
                        placeholder="Enter OTP"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {otpError && <RedText>{otpError}</RedText>}
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn_man"
                        disabled={isVerifying}
                      >
                        {isVerifying ? "Loading..." : "Verify OTP"}
                      </button>
                      <button
                        onClick={handleResendOtp}
                        type="button"
                        className="btn_man ml-10"
                        disabled={isResending || countdown > 0}
                      >
                         {isResending
                          ? "Loading..."
                          : countdown > 0
                          ? `Resend OTP ${countdown}`
                          : "Resend OTP"}
                      </button>
                    </div>
                    
                  </form>
                )} */}
                <div className="form-group text-center">
                  <span>Don't have an Account? Let's </span>
                  <NavLink className="color1" to="/register">
                    Register
                  </NavLink>
                </div>
                <div>
                  <p className=" alert alert-danger text-center">
                  <strong>Note: For now, your default password is 123456.
                  <br/>
Go to the setting and change your password. Be careful by doing this if you you forgot your password, you will never recover this.</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
