import React from "react";

const NotFound = () => {
  return (
    <>
      <div class="not_found_page for04 text-center">
        <div class="m-auto">
          <h1>
            4
            <span class="mr-2">
              <img src="img/tap.png" />
            </span>
            4
          </h1>
          <h2 class="mb-4">
            Sorry, The Page You're Looking For
            <br /> Cannot be Accessed
          </h2>
          <a href="/" class="btn btn_man">
            Back To Home
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
